import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import styles from "../../components/globalstyles";
import { LoadingButton } from "@mui/lab";
import handleInput from "../../utils/handleInput";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import validator from "validator";

const Register = () => {
  const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (!query.get("token")) {
      navigate("/");
    }
  }, []);

  const [form, setForm] = useState({ token: query.get("token") });
  const { loadApi, loadingApi, errorApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const navigate = useNavigate();

  useEffect(() => {
    if (!query.get("token")) {
      navigate("/");
    }
  }, []);

  const changeForm = (name) => {
    return handleInput(name, setForm);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (form.password.length < 8) {
      showSnack(
        "La contraseña debe contener por lo menos 8 caracteres",
        "warning"
      );
      return;
    }

    if (form.password !== form.password2) {
      showSnack("Las contraseñas deben coincidir", "warning");
      return;
    }

    loadApi("reset_password", false, "post", form)
      .then((response) => {
        navigate("/success?type=reset_password");
      })
      .catch((e) => {});
  }

  return (
    <>
      {/* HEADER (SOLO MOVIL) */}
      <Box sx={styles.barramovil}>
        <img src="/assets/clipboard4.svg" alt="Radar Freim" />
      </Box>

      <Grid container sx={styles.root}>
        {/* VISTA IZQUIERDA */}
        <Grid item md={7} sx={styles.image}></Grid>
        {/* VISTA DERECHA */}
        <Grid container item md={5} px={{ xs: 4, lg: 7 }}>
          <Stack
            component="form"
            role="form"
            onSubmit={handleSubmit}
            spacing={2}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ height: { xs: "80px", lg: "120px" } }}>
              <img alt="logo" src="/assets/logo.svg" height="100%" />
            </Box>
            <Stack direction="row" justifyContent="left" width="100%">
              <Typography fontSize="1.6rem" align="left">
                Restablecer Contraseña
              </Typography>
            </Stack>
            <Stack spacing={1.5} width="100%">
              {errorApi && (
                <Alert sx={{ mb: 2 }} severity="error">
                  {errorApi}
                </Alert>
              )}
              <TextField
                fullWidth
                color="warning"
                variant="outlined"
                label="Contraseña*"
                onChange={changeForm("password")}
                type="password"
              />
              <TextField
                fullWidth
                color="warning"
                variant="outlined"
                label="Repite Contraseña*"
                onChange={changeForm("password2")}
                type="password"
              />
            </Stack>
            <LoadingButton
              loading={loadingApi.includes("post__reset_password")}
              variant="contained"
              type="submit"
              sx={styles.button}
            >
              RESTABLECER
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default Register;
