import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import Audio from "react-loading-icons/dist/esm/components/audio";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ViewArtistSends() {
  const [mainData, setMainData] = useState([]);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { loadApi, loadedApi, loadingApi } = useApi();

  useEffect(() => {
    loadApi("productor/envios", true, "get")
      .then((response) => {
        setMainData(
          response.data.map((data) => {
            return {
              name: data.cancion,
              artista: data.artista,
              respuesta: data.status,
              songId: data.songId,
              status: data.status,
            };
          }) || []
        );
      })
      .catch((e) => {
        showSnack(e.message, "error", false);
      });
  }, []);

  return (
    <>
      <GlobalHeader />
      {!loadedApi.includes("get__productor/envios") && mainData.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: `calc(100vh - ${
              window.document.getElementById("GlobalHeader")?.offsetHeight ||
              "64"
            }px - 32px)`,
          }}
        >
          <Audio fill="#F27224" />
        </Box>
      ) : (
        <Container>
          <Grid container spacing={5}>
            <Grid container item spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary.main" fontWeight="900">
                  Historial de Envios
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {mainData.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 670 }}>
                      {/* 1 seccion */}
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cancion enviada
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Artista
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Estado
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Respuesta
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>

                      {/* 2 seccion */}
                      <TableBody>
                        {mainData.map((row, i) => (
                          <StyledTableRow key={i}>
                            <StyledTableCell component="th" align="center">
                              <Link to={`/productor/cancion/${row.songId}/`}>
                                {row.name}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.artista}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{ textTransform: "uppercase" }}
                            >
                              {row.status}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.respuesta === "pending"
                                ? "Aguardando respuesta"
                                : "Finalizada"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack alignItems="center">
                    <Typography variant="h5" mt={5} textAlign="center">
                      No hay pagos registrados
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
