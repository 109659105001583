import styled from "@emotion/styled";
import {
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { useState } from "react";
import Audio from "react-loading-icons/dist/esm/components/audio";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const CancionesAdmin = () => {
  const { loadApi, loadedApi } = useApi();
  const { userId } = useParams();
  const [canciones, setCanciones] = useState([]);
  const [isloading, setIsloading] = useState(true);

  // load api canciones userId
  useEffect(() => {
    loadApi(`admin/cancionesProductor/${userId}`, true, "get")
      .then((res) => {
        setIsloading(false);
        setCanciones(res.data);
      })
      .catch((error) => {
        setIsloading(false);
        console.error(error);
      });
  }, []);

  return (
    <>
      <>
        <GlobalHeader />
        <Container>
          <Stack pb={6}>
            <Typography variant="h4">Canciones pendientes</Typography>
            <Stack alignItems="end" sx={{ gap: "20px" }}>
              <TextField variant="outlined" placeholder="Buscar..."></TextField>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> ID cancion </StyledTableCell>
                      <StyledTableCell> Nombre canción </StyledTableCell>
                      <StyledTableCell> Idioma </StyledTableCell>
                      <StyledTableCell> Genero </StyledTableCell>
                      <StyledTableCell> Url de la canción </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isloading && canciones.length === 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5}>
                          <Audio fill="#F27224" />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {canciones.length === 0 && !isloading ? (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5}>
                          <Typography variant="h5">
                            No hay resultados
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      canciones.map((item, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell>{item.id}</StyledTableCell>
                          <StyledTableCell>{item.nombre}</StyledTableCell>
                          <StyledTableCell>{item.idioma}</StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: "600", fontStyle: "italic" }}
                          >
                            {item.genero}
                          </StyledTableCell>
                          <StyledTableCell>
                            <a
                              href={item.url}
                              target="_blank"
                              style={{
                                color: "blue",
                              }}
                            >
                              ¡Escuchala aquí! 🎵🎶
                            </a>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Container>
      </>
    </>
  );
};

export default CancionesAdmin;
