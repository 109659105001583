import { LoadingButton } from "@mui/lab";
import {
  Box,
  InputLabel,
  Stack,
  TextField,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { forwardRef, useEffect, useState } from "react";
import SnackAlert from "../../../components/SnackAlert";
import { useUser } from "../../../hooks/useUser";

// share react
import { FacebookShareButton, TwitterShareButton } from "react-share";
//icons
import { FaFacebook, FaTwitter, FaSpotify } from "react-icons/fa";
import useSnack from "../../../hooks/useSnack";
import useApi from "../../../hooks/useApi";
import { Globals } from "../../../utils/environtments";

import axios from "axios";
import spotifyToEmbed from "../../../utils/spotifyToEmbed";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//style modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: 300, sm: "400" },
  maxHeight: 500,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  "::-webkit-scrollbar": {
    display: "none",
  },
};

export default function ModalView({ open, setOpen, loadingApi, loadDecision }) {
  const { updateUser } = useUser();
  const { loadApi } = useApi();
  const [shareMessage, setShareMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  //cerrar modal
  const handleOpenModal = () => {
    setOpenModal(false);
  };

  //Traer mensaje de compartir
  useEffect(() => {
    loadApi("general/parametros", false, "get").then((res) => {
      setShareMessage(res.data.sharedMessage);
    });
  }, []);

  //Bloqueamos pegar en input feedback
  const handlePaste = (event) => {
    event.preventDefault();
    showSnack("No es posible pegar en este campo.", "warning");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { status, url } = open;
  //form
  const [form, setForm] = useState({
    status: "",
    url_post: null,
    feedback: null,
  });

  const handleClose = () => {
    setForm({ status: "", url_post: "", feedback: "" });
    setOpen(false);
  };

  const handleSubmit = () => {
    const data = { ...form, status: status };
    if (data.status === "rejected" && data.feedback.length >= 100) {
      loadDecision(open.id, data);
      setForm({});
    } else if (data.status === "approve") {
      loadDecision(open.id, data);
      updateUser();
      setForm({});
    } else {
      showSnack("Minimo 100 caracteres", "warning");
    }
  };

  //spotify
  const CLIENT_ID = Globals.SPOTIFY_CLIENT_ID;
  const REDIRECT_URI = Globals.REDIRECT_URI;
  const RESPONSE_TYPE = Globals.RESPONSE_TYPE;
  const AUTH_ENDPOINT = Globals.AUTH_ENDPOINT;
  const [spotify, setSpotify] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [loadingSpotify, setLoadingSpotify] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.hash.slice(1));
    if (params.get("access_token")) {
      localStorage.setItem("access_token_spotify", params.get("access_token"));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token_spotify")) setSpotify(true);
  }, []);

  //Ver las playlist
  const handlePLaylists = async () => {
    try {
      const { data } = await axios.get(
        "https://api.spotify.com/v1/me/playlists",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token_spotify"
            )}`,
          },
          params: { limit: 10 },
        }
      );
      setSpotify(true);
      setOpenModal(true);
      setPlaylists(data.items);
    } catch (error) {
      showSnack("La sesión de spotify expiró, vuelva a iniciar.", "warning");
      setSpotify(false);
    }
  };

  //funcion para transformar una url de spotify a uri track
  const urlToSpotifyUri = (url) => {
    const match = url.match(
      /https:\/\/open\.spotify\.com\/(?:intl-es\/)?track\/([\w\d]+)/
    );

    if (match) {
      const trackId = match[1];
      const spotifyUri = `spotify:track:${trackId}`;
      return spotifyUri;
    } else {
      // URL no válida de Spotify
      return null;
    }
  };

  //endpoint de agregar cancion a playlist
  const handleAddToPlaylist = async (playlist_id) => {
    setLoadingSpotify(true);
    const uris = [urlToSpotifyUri(url)];
    console.log(uris);
    try {
      const { data } = await axios.post(
        `https://api.spotify.com/v1/playlists/${playlist_id}/tracks`,
        { uris: uris },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token_spotify"
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoadingSpotify(false);
      showSnack("¡Canción agregada!");
      console.log(data);
    } catch (error) {
      setLoadingSpotify(false);
      if (error.response.data.error.status === 401)
        showSnack("Sesión expiró vuelva a inicar sesion de spotify", "error");
      else showSnack(error.response.data.error.message, "error");
    }
  };

  const handleSignInSpotify = async () => {
    window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=playlist-modify-public`;
  };

  return (
    <>
      <Dialog
        open={Boolean(open)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            mb: 2,
            py: 1.5,
            borderBottom:
              open.status === "approve" ? "2px solid green" : "2px solid red",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              {open.status === "approve"
                ? "Aprobar Canción"
                : "Rechazar canción"}
            </Box>
            <Box
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              x
            </Box>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            {open.status === "approve" && (
              <Stack>
                <InputLabel>URL del post</InputLabel>
                <TextField
                  type="text"
                  placeholder="Pega aqui el enlace del post"
                  value={form.url_post}
                  sx={{ width: { xs: "100%", sm: "500px" } }}
                  onChange={(e) =>
                    setForm({ ...form, url_post: e.target.value })
                  }
                />
              </Stack>
            )}
            <Stack spacing={1}>
              <InputLabel>
                Feedback {status === "approve" && "(opcional)"}{" "}
              </InputLabel>
              <TextField
                type="text"
                onPaste={handlePaste}
                value={form.feedback}
                placeholder="Dale tu opinión al músico, pero se amable. (minimo 100 caracteres)"
                sx={{ width: { xs: "100%", sm: "500px" } }}
                onChange={(e) => setForm({ ...form, feedback: e.target.value })}
                multiline
                rows={4}
              />
            </Stack>
          </Stack>
          {status === "approve" && (
            <Stack>
              <Typography variant="body1" my={0.8}>
                Compartir en:
              </Typography>
              <Stack direction="row" spacing={1}>
                <FacebookShareButton url={url} quote={shareMessage}>
                  <FaFacebook fontSize="28px" color="#3b5998" />
                </FacebookShareButton>
                <TwitterShareButton url={url} title={shareMessage}>
                  <FaTwitter fontSize="28px" color="#3b5998" />
                </TwitterShareButton>

                {!spotify && spotifyToEmbed(url) ? (
                  <IconButton onClick={handleSignInSpotify}>
                    <FaSpotify fontSize="28px" color="#81b71a" />
                  </IconButton>
                ) : (
                  spotify &&
                  spotifyToEmbed(url) && (
                    <IconButton onClick={handlePLaylists}>
                      <FaSpotify fontSize="28px" color="#81b71a" />
                    </IconButton>
                  )
                )}
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={{ pb: 2 }}>
          <LoadingButton
            loading={loadingApi.includes(`put__productor/envios/${open.id}`)}
            // onClick={() => loadDecision(open.id, form)}
            onClick={() => handleSubmit()}
            color={open.status === "approve" ? "success" : "error"}
          >
            Continuar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Modal
        open={openModal}
        onClose={handleOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6">¡Elige tu playlist!</Typography>
          <Stack mt={4} spacing={2}>
            {playlists.map((item) => (
              <Stack
                key={item.id}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Stack>
                    <img
                      src={item.images[0]?.url}
                      width="50px"
                      height="50px"
                      style={{ objectFit: "cover" }}
                    />
                  </Stack>
                  <Stack justifyContent="space-between" maxWidth="380px">
                    <Typography
                      variant="subtitle1"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="GrayText"
                      fontWeight={400}
                    >
                      Playlist colaboratíva: {item.colaborative ? "Si" : "No"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack justifyContent="center">
                  <LoadingButton
                    loading={loadingSpotify}
                    onClick={() => handleAddToPlaylist(item.id)}
                    sx={{ minWidth: "auto" }}
                    variant="text"
                  >
                    <AddCircleOutlinedIcon />
                  </LoadingButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
