import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";

export default function Step2P({
  setSelectedFiles,
  setImageUpload,
  imageUpload,
  selectedFiles,
  showSnack,
}) {
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      setSelectedFiles(e.target.files[0])
      reader.onload = (event) => {
        setImageUpload(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  return (
    <>
      <Stack spacing={1} pb={4}>
        <Typography variant="h6" align="center">
          Selecciona una foto de tu medio con la cual te identificas:
        </Typography>
        <Typography variant="p" align="center">
          Te recomendamos subir fotos cuadradas de aspecto 1:1
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        flexWrap="wrap"
        gap={4}
        pb={3}
      >
        <LoadingButton
          component="label"
          variant="outlined"
          sx={{
            backgroundImage: `url(${imageUpload})`,
            borderRadius: "4px",
            backgroundColor: "white",
            backgroundSize: "100% 100%",
            width: "150px!important",
            height: "150px!important",
          }}
        >
          {!imageUpload && "+"}
          <input
            type="file"
            id="fileUpload"
            onChange={handleFileChange}
            style={{ width: "100%", height: "100%" }}
            hidden
            className="inputFileInput"
            accept="image/*"
          />
        </LoadingButton>
      </Stack>
    </>
  );
}
