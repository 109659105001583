import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import styles from "../../components/globalstyles";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../hooks/useUser";
import handleInput from "../../utils/handleInput";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import validator from "validator";

const Register = () => {
  const [form, setForm] = useState("");
  const { loadApi, loadingApi, errorApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    if (!validator.isEmail(form)) {
      showSnack("El correo electrónico no es válido", "warning");
      return;
    }

    loadApi("password_recovery", false, "post", { username: form })
      .then((response) => {
        navigate("/success?type=password_recovery");
      })
      .catch((e) => {});
  }

  return (
    <>
      {/* HEADER (SOLO MOVIL) */}
      <Box sx={styles.barramovil}>
        <img src="/assets/clipboard4.svg" alt="Radar Freim" />
      </Box>

      <Grid container sx={styles.root}>
        {/* VISTA IZQUIERDA */}
        <Grid item md={7} sx={styles.image}></Grid>
        {/* VISTA DERECHA */}
        <Grid container item md={5} px={{ xs: 4, lg: 7 }}>
          <Stack
            component="form"
            role="form"
            onSubmit={handleSubmit}
            spacing={2}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ height: { xs: "80px", lg: "120px" } }}>
              <img alt="logo" src="/assets/logo.svg" height="100%" />
            </Box>
            <Stack direction="row" justifyContent="left" width="100%">
              <Typography fontSize="1.6rem" align="left">
                Recuperar Contraseña
              </Typography>
            </Stack>
            <Stack spacing={1.5} width="100%">
              {errorApi && (
                <Alert sx={{ mb: 2 }} severity="error">
                  {errorApi}
                </Alert>
              )}
              <TextField
                color="warning"
                variant="outlined"
                fullWidth
                label="Correo Electronico*"
                value={form.username}
                onChange={(e) => setForm(e.target.value)}
              />
            </Stack>
            <LoadingButton
              loading={loadingApi.includes("post__password_recovery")}
              variant="contained"
              type="submit"
              sx={styles.button}
            >
              ENVIAR
            </LoadingButton>
            <Link to="/login">
              <Typography color="primary" sx={{ textDecoration: "underline" }}>
                INICIAR SESIÓN
              </Typography>
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default Register;
