import {
  Autocomplete,
  Box,
  Button,
  Chip,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import countries from "../../utils/countries";
import { useState } from "react";
import generos from "../../utils/generos";

export default function Step1P({
  changeForm,
  mainData,
  tags,
  setTags,
  showSnack,
}) {
  const [inputValue, setInputValue] = useState("");

  const countryOptions = Object.entries(countries).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const generoOptions = generos.map((item) => ({
    value: item,
    label: item,
  }));

  const handleAdd = () => {
    if (!inputValue) {
      showSnack("No has ingresado ningún género válido!", "warning");
      return;
    }

    if (tags.includes(inputValue)) {
      showSnack("Ya ingresaste este género!", "warning");
      return;
    }

    setTags((prevTags) => [...prevTags, inputValue]);

    setInputValue("");
  };

  const handleDelete = (tagToDelete) => () => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  return (
    <Stack alignItems="center">
      <Stack
        spacing={2.3}
        width={{ xs: "100%", sm: "90%", md: "70%", lg: "50%" }}
      >
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography variant="p">
              Ingresa el país donde se ubica tu proyecto:
            </Typography>
            {mainData?.pais && (
              <img
                src={`https://flagcdn.com/${mainData.pais}.svg`}
                alt="País"
                width="30"
                height="20"
              />
            )}
          </Stack>

          <Autocomplete
            disablePortal
            disableClearable
            onChange={changeForm("pais")}
            options={countryOptions}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">
            Ingresa los géneros musicales que aceptas:
          </Typography>
          <Stack spacing={1} direction="row" alignItems="center">
            <Autocomplete
              disablePortal
              disableClearable
              onChange={(event, value, reason, details) =>
                setInputValue(details.option.value)
              }
              options={generoOptions}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            <Button onClick={handleAdd}>Agregar</Button>
          </Stack>
          <Stack direction="row" gap={1} marginTop={1} flexWrap="wrap">
            {tags.map((tag, i) => (
              <Chip key={i} label={tag} onDelete={handleDelete(tag)} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
