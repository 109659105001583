// mostrar fecha y hora en formato legible
export const getDateAndHour = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleString("es-CO", options);
};

//mostrar tiempo restante
export const getTime = (dateString) => {
  const targetDays = 3;
  const targetMilliseconds = targetDays * 24 * 60 * 60 * 1000;

  const currentDate = new Date();
  const targetDate = new Date(dateString);
  targetDate.setTime(targetDate.getTime() + targetMilliseconds);

  const timeDifference = targetDate - currentDate;

  const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  const hours = Math.floor(
    (timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
  );

  return `${days} Día(s) y ${hours} Hora(s)`;
};
