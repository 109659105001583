import { isURL } from "validator";

const youtubeToEmbed = (url) => {
  // Verificar que la URL sea válida
  if (!isURL(url)) {
    console.log("La URL proporcionada no es válida");
    return;
  }

  // Crear una nueva URL a partir del string
  const parsedUrl = new URL(url);

  // Verificar que la URL pertenezca a YouTube
  if (
    parsedUrl.hostname !== "www.youtube.com" &&
    parsedUrl.hostname !== "youtu.be" &&
    parsedUrl.hostname !== "youtube.com"
  ) {
    return;
  }

  // Extraer el ID del video y construir la URL de embed
  let videoId;
  if (
    parsedUrl.hostname === "www.youtube.com" ||
    parsedUrl.hostname === "youtube.com"
  ) {
    videoId = parsedUrl.searchParams.get("v");
  } else if (parsedUrl.hostname === "youtu.be") {
    videoId = parsedUrl.pathname.slice(1);
  }
  // Devolver la URL de embed
  return `https://www.youtube.com/embed/${videoId}`;
};

export default youtubeToEmbed;
