import isURL from "validator/lib/isURL";

export const bandcampToEmbed = (codigoEmbed) => {
  // Buscar el inicio de la propiedad src
  const inicioSrc = codigoEmbed.indexOf('src="');
  if (inicioSrc === -1) {
    // La propiedad src no se encontró
    return;
  }

  // Buscar el final de la propiedad src
  const finSrc = codigoEmbed.indexOf('"', inicioSrc + 5);
  if (finSrc === -1) {
    // No se encontró el cierre de las comillas de la propiedad src
    return;
  }

  // Obtener el valor de src
  const srcValue = codigoEmbed.substring(inicioSrc + 5, finSrc);

  // Validar si src contiene una URL de SoundCloud
  const soundCloudURL = "https://bandcamp.com/";
  
  if (!isURL(srcValue)) return;

  if (srcValue.includes(soundCloudURL)) {
    return srcValue;
  } else {
    return;
  }
};
