const generos = [
  "Clásica o instumental",
  "Instrumental",
  "Solo piano",
  "Música clásica",
  "Electrónica",
  "Acid House",
  "Ambient",
  "Bass Music",
  "Beats / lo-fi",
  "Chill Out",
  "Dance Music",
  "Deep House",
  "Drum And Bass",
  "Dubstep",
  "Electro Swing",
  "French House",
  "Future House",
  "Hardstyle / hardcore",
  "House Music",
  "Minimal",
  "Nu-Disco",
  "Phonk",
  "Progressive / Melodic House",
  "Synthwave",
  "Disco",
  "Folk / Acústico",
  "Indie Folk",
  "Solista",
  "Hip Hop / Rap",
  "Hip Hop",
  "Rap",
  "Trap",
  "Jazz",
  "Jazz Fusion",
  "Modern Jazz",
  "Clasic Jazz",
  "Pop",
  "Afrobeat",
  "Afro pop",
  "Pop comercial",
  "Dance pop",
  "Dream pop",
  "Electropop",
  "Indiepop",
  "Pop rock",
  "Latin pop",
  "Lofi bedroom",
  "Pop soul",
  "Progressive pop",
  "Psychedelic pop",
  "Synthpop",
  "R&B / soul",
  "Funk",
  "Soul",
  "Urban Pop",
  "Bossa Nova",
  "Rock / Punk",
  "Rock",
  "Rock alternativo",
  "Blues",
  "Coldwave",
  "Rock experimental",
  "Garage rock",
  "Hard rock",
  "Indie rock",
  "New wave",
  "Pop punk",
  "Punk",
  "Punk rock",
  "Post punk",
  "Post rock",
  "Indie pop",
  "Dream rock",
  "Psychodelic rock",
  "Rock & roll",
  "Shoegaze",
  "Ska",
  "Heavy metal",
  "Noise",
  "Happy punk",
  "Emo",
  "Urbano",
  "Reguetón alternativo",
  "Reguetón clásico",
  "Reguetón romántico",
  "Neoperreo",
  "Perreo",
  "Malianteo",
  "Reguetón hardcore",
  "Música Popular",
  "Cumbia",
  "Bachata",
  "Samba",
  "Tango",
  "Merengue",
  "Salsa",
  "Mambo",
  "Rumba",
  "Regional Mexicana",
  "Mariachi",
  "Corridos",
  "Corridos Tumbados",
  "Ranchera",
  "Banda Sinaloense",
  "Norteña",
  "Boleros",
  "Balada romántica",
  "Metal",
  "Heavy Metal",
  "Death Metal",
  "Nü Metal",
  "Black Metal",
  "Trash Metal",
  "Proggressive Metal",
];

export default generos;
