const styles = {
  ctopciones: {
    color: "white",
  },

  hlogo: {
    width: "200px",
    height: "92px",
    borderRadius: 0,
  },

  hicono: {
    width: 60,
    height: 60,
    mb: 2,
  },

  imagen: {
    width: "50px",
    height: "50px",
  },
};

export default styles;
