import { Scrollama, Step } from "react-scrollama";
import { useState } from "react";

const Scrollamatest = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };

  const arrayImages = [
    "/assets/clipboard.svg",
    "/assets/clipboard2.svg",
    "/assets/clipboard3.svg",
    "/assets/disco.png",
  ]
  return (
    <>
      <div
        style={{
          margin: "50vh 0",
          marginTop: "calc(50vh - 45px)",
          border: "2px dashed skyblue",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <div style={{width: "50%"}}>
          <div
            className="sticky"
            style={{
              position: "sticky",
              top: "calc(50% - 45px)",
              border: "1px solid orchid",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              width: "100%",
              height: "90px"
            }}
          >
            <img style={{width: "100%"}} src={currentStepIndex} />
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", flexGrow: "1"}}>
          <Scrollama offset={.5} onStepEnter={onStepEnter} debug>
            {arrayImages.map((item, stepIndex) => (
              <Step data={item} key={stepIndex}>
                <div
                  style={{
                    margin: "0",
                    border: "1px solid gray",
                    opacity: currentStepIndex === item ? 1 : 0.2,
                  }}
                >
                  <div>
                    <h1>Index {stepIndex}</h1>
                  </div>
                </div>
              </Step>
            ))}
          </Scrollama>
        </div>
      </div>
    </>
  );
};

export default Scrollamatest;
