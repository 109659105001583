import {
  Container,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";
import { useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import useSnack from "../../../hooks/useSnack";
import SnackAlert from "../../../components/SnackAlert";
import TableStyled from "../../../components/table";

const initValues = {
  code_voucher: "",
  description: "",
  radar_coin: "",
  start_discount: "",
  end_discount: "",
};

const headersTable = [
  { label: "CODIGO", ref: "code_voucher" },
  { label: "DESCRIPCION", ref: "description" },
  { label: "FECHA INICIO", ref: "start_discount" },
  { label: "FECHA FIN", ref: "end_discount" },
  { label: "CONTENIDO", ref: "radar_coin" },
];

const Bonos = () => {
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [form, setForm] = useState(initValues);
  const [data, setData] = useState([]);

  useEffect(() => {
    getVouchers();
  }, []);

  const getVouchers = async () => {
    try {
      const { data } = await loadApi("admin/bonos", true, "get");
      setData(data);
    } catch (error) {
      showSnack(error.message, "error");
    }
  };

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setForm((props) => ({
      ...props,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (let key of Object.keys(form)) {
      if (!form[key]) {
        showSnack("Todos los capos son requeridos.", "warning");
        if (key === "start_discount" && form[key] > form.end_discount) {
          showSnack(
            "La fecha de inicio debe ser menor a la fecha final",
            "warning"
          );
        }
      }
    }

    const body = {
      ...form,
      code_voucher: form.code_voucher.trim().toUpperCase(),
      radar_coin: Number(form.radar_coin),
    };

    try {
      const { data } = await loadApi("admin/bonos/create", true, "post", body);
      showSnack(data.message, "success");
      getVouchers();
    } catch (error) {
      showSnack(String(error.message), "error");
    }
  };

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack spacing={4}>
          {/* Agregar nuevo */}
          <Stack bgcolor="#f1f1f1" p={3} borderRadius="10px" spacing={4}>
            <Typography variant="subtitle1">Agregar bono de regalo</Typography>
            <Stack
              component="form"
              role="form"
              onSubmit={handleSubmit}
              spacing={2}
            >
              <TextField
                label="Codigo"
                name="code_voucher"
                onChange={handleChange}
              />
              <TextField
                multiline
                rows={4}
                label="Descripcion"
                name="description"
                onChange={handleChange}
              />
              <Stack
                spacing={2}
                direction={{ xs: "column", md: "row" }}
                alignItems={{ md: "flex-end" }}
              >
                <Stack flex={0.5}>
                  <TextField
                    label="Radar coin"
                    name="radar_coin"
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    sx={{
                      "input::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    }}
                  />
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  flex={1}
                >
                  <Stack flex={1}>
                    <FormLabel>Fecha inicial del bono</FormLabel>
                    <TextField
                      name="start_discount"
                      onChange={handleChange}
                      type="date"
                    />
                  </Stack>
                  <Stack flex={1}>
                    <FormLabel>Fecha final del bono</FormLabel>
                    <TextField
                      name="end_discount"
                      onChange={handleChange}
                      type="date"
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack alignItems="flex-end">
                <LoadingButton
                  loading={loadingApi.includes("post__admin/bonos/create")}
                  sx={{ padding: "7px 32px" }}
                  type="submit"
                >
                  Crear
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
          {/* Tabla para ver los bonos */}
          <TableStyled
            headers={headersTable}
            data={data}
            loading={loadingApi.includes("get___admin/bonos")}
          />
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default Bonos;
