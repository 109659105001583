import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { LoadingButton } from "@mui/lab";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { Audio } from "react-loading-icons";
import ModalView from "./modal";
import Cancion from "./cancion";
import CancionPc from "./cancionPC";

export default function ViewProductorSends() {
  const { loadApi, loadingApi } = useApi();
  const { songId } = useParams();
  const { user, updateUser } = useUser();
  const [mainData, setMainData] = useState(false);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openDialog, setOpenDialog] = useState(false);
  const isSM = useMediaQuery(useTheme().breakpoints.down("md"));

  useEffect(() => {
    loadApi(`productor/canciones/allPendings`, true, "get")
      .then((response) => {
        setMainData(response.data || false);
      })
      .catch((e) => {
        showSnack(e.message, "error", false);
      });
  }, []);

  function updateSend(status, id, url) {
    setOpenDialog({ status, id, url });
  }

  function loadDecision(id, form) {
    loadApi(`productor/envios/${id}`, true, "put", form)
      .then((response) => {
        showSnack(response.data.message);
        setOpenDialog(false);
        setMainData((aData) => aData.filter((item) => item.id !== id));
        updateUser()
      })
      .catch((error) => {
        showSnack(error.message, "error");
        console.log(form.status)
      });
  }

  return (
    <>
      <GlobalHeader />
      {!mainData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: `calc(100vh - ${
              window.document.getElementById("GlobalHeader")?.offsetHeight ||
              "64"
            }px - 32px)`,
          }}
        >
          <Audio fill="#F27224" />
        </Box>
      ) : mainData.length > 0 && isSM ? (
        mainData.map((item, i) => (
          <Cancion
            key={i}
            mainData={item}
            showSnack={showSnack}
            loadingApi={loadingApi}
            updateSend={updateSend}
          />
        ))
      ) : mainData.length > 0 && !isSM ? (
        <CancionPc
          mainData={mainData}
          showSnack={showSnack}
          loadingApi={loadingApi}
          updateSend={updateSend}
        />
      ) : (
        <Container>
          <Stack
            sx={{
              height: "calc( 100vh - 104px )",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src="/assets/logo.svg" style={{ width: "300px" }} />
            <Typography variant="h5" mt={4} textAlign="center">
              ¡Upps! No hay Envios pendientes
            </Typography>
          </Stack>
        </Container>
      )}
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <ModalView
        open={openDialog}
        loadingApi={loadingApi}
        setOpen={setOpenDialog}
        loadDecision={loadDecision}
      />
    </>
  );
}
