import { useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import {
  Box,
  Container,
  Stack,
  Typography,
  Backdrop,
  Modal,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import useApi from "../../../hooks/useApi";

import { FaPaypal } from "react-icons/fa";
import { PiCreditCard } from "react-icons/pi";
import { LoadingButton } from "@mui/lab";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "20px",
};



const TiendaArtista = () => {
  const [array, setArray] = useState([
    {
      plan: "5 RadarCoins",
      amount: "10",
      radarCoin: "5",
      description:
        "este paquete contiene 5 RadarConis para gastarlos en canciones!",
      color: "#DEFF00"
    },
    {
      plan: "10 RadarCoins",
      amount: "19",
      radarCoin: "10",
      description:
        "este paquete contiene 10 RadarConis para gastarlos en canciones!",
      color: "#FFFF00"
    },
    {
      plan: "30 RadarCoins",
      amount: "54",
      radarCoin: "30",
      description:
        "este paquete contiene 30 RadarConis para gastarlos en canciones!",
      color: "#FFC600"
    },
    {
      plan: "50 RadarCoins",
      amount: "85",
      radarCoin: "50",
      description:
        "este paquete contiene 50 RadarConis para gastarlos en canciones!",
      color: "#FF9C00"
    },
    {
      plan: "100 RadarCoins",
      amount: "160",
      radarCoin: "100",
      description:
        "este paquete contiene 100 RadarConis para gastarlos en canciones!",
      color: "#FF5A04"
    },
    {
      plan: "200 RadarCoins",
      amount: "300",
      radarCoin: "200",
      description:
        "este paquete contiene 200 RadarConis para gastarlos en canciones!",
      color: "#FF0000"
    },
  ]);

  const styles = {
    content: {
      gap: "40px",
      justifyContent: "center",
      flexWrap: "wrap",
      py: 4,
    },
    boxes: {
      gap: "20px",
      aligItems: "center",
      textAlign: "center",
      width: "300px",
      py: 4,
      px: 2,
      backgroundColor: "#F27224",
      borderRadius: "10px",
      animation: "fade .2s linear",
      ":hover": {
        transform: "scale(1.02)",
        transition: ".3s ease",
        cursor: "pointer",
      },
      "@keyframes fade": {
        "0%": {
          opacity: 0,
          transform: "translateY(-30px)",
        },
        "100%": {
          opacity: 1,
          transform: "translateY(0)",
        },
      },
    },
  };
  const { loadApi, loadingApi } = useApi();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModal = (data) => {
    setData(data)
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleBuy = (pay_method) => {
    const adata = { ...data, pay_method: pay_method };
    setOpen(true);
    loadApi("artista/payments", true, "post", adata)
      .then((res) => {
        window.open(res.data?.url, "_blank");
        setOpen(false);
      })
      .catch((e) => {
        setOpen(false);
      });
  };

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={styleModal}>
          <Stack spacing={2}>
            <Typography variant="h6" textAlign="center" color="grayText" >
              Seleccione metodo de pago
            </Typography>
            <LoadingButton
              loading={loadingApi.includes("post__artista/payments")}
              sx={{fontSize: "20px", backgroundColor: "#3b7bbf", ":hover": {backgroundColor: "#3b7bbf", opacity: ".8", transition: ".2s linear"}}}
              startIcon={<FaPaypal />}
              onClick={()=> handleBuy("paypal")}
            >
              Paypal
            </LoadingButton>
            <LoadingButton
              loading={loadingApi.includes("post__artista/payments")}
              sx={{fontSize: "20px", backgroundColor: "#008cdd", ":hover": {backgroundColor: "#008cdd", opacity: ".8", transition: ".2s linear"}}}
              startIcon={<PiCreditCard />}
              onClick={()=> handleBuy("stripe")}
            >
              Credito / debito
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: 9999 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <GlobalHeader />
      <Container>
        <Stack
          sx={{
            minHeight: `calc(100vh - ${
              window.document.getElementById("GlobalHeader")?.offsetHeight ||
              "64"
            }px - 32px)`,
          }}
        >
          <Typography variant="h4">Tienda</Typography>
          <Stack direction="row" sx={styles.content}>
            {array.map((item, i) => (
              <Stack
                key={i}
                sx={{...styles.boxes, backgroundColor: item.color}}
                onClick={() =>
                  handleOpenModal({ plan: item.plan, amount: item.amount, radarCoin: item.radarCoin })
                }
              >
                <Stack sx={{ width: "100%" }} alignItems="center">
                  <img src="/assets/disco.png" style={{ width: "200px" }} />
                </Stack>
                <Typography
                  variant="h5"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LocalAtmIcon /> {item.plan}
                </Typography>
                <Stack>
                  <Typography variant="h6" sx={{ color:  ["10", "19", "54"].includes(item.amount) ? "#000" : "#fff" }}>
                    ${item.amount} USD
                  </Typography>
                  <Typography variant="p" sx={{ color:  ["10", "19", "54"].includes(item.amount) ? "#000" : "#fff" }}>
                    {item.description}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default TiendaArtista;
