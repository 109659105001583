import { Skeleton, Stack, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import youtubeToEmbed from "../../../utils/youtubeToEmbed";
import spotifyToEmbed from "../../../utils/spotifyToEmbed";

const IndCard = ({ data, loading }) => (
  <>
    {loading ? (
      <Stack spacing={2} pb={2} alignItems="center">
        <Skeleton width={250} height={250} />
        <Skeleton width="250px" height="auto" />
      </Stack>
    ) : (
      <Link to={`/artista/cancion/${data.id}/`}>
        <Stack spacing={2} pb={2} alignItems="center">
          <img
            src={data.url_img}
            style={{ borderRadius: "4px/6.7px", objectFit: "cover" }}
            alt={data.titulo}
            width={250}
            height={250}
          />
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignContent="center"
          >
            <Typography
              fontWeight={600}
              noWrap
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "200px",
              }}
            >
              {data.nombre}
            </Typography>
            <img
              src={`https://flagcdn.com/${data.pais}.svg`}
              alt="País"
              width="30"
              height="20"
            />
          </Stack>
        </Stack>
      </Link>
    )}
  </>
);

export default IndCard;
