import { Alert, Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import styles from "../../components/globalstyles";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../hooks/useUser";
import { useEffect } from "react";
import bg from "../../assets/wallpaper-login.webp";

const Login = () => {
  const [form, setForm] = useState({ username: "", password: "" });
  const { loadApi, loadingApi, errorApi } = useApi();
  const [logo, setLogo] = useState(null);
  const { setUser } = useUser();
  const navigate = useNavigate();

  function handleSignin(e) {
    e.preventDefault();
    setForm((data) => {
      if (data.username) data["username"] = data.username.toLowerCase();
      return data;
    });

    loadApi("login", false, "post", form)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setUser(response.data.user);
        navigate("/");
      })
      .catch((e) => {
        console.log("");
      });
  }

  //Traer mensaje de compartir
  useEffect(() => {
    loadApi("general/parametros", false, "get").then(({ data }) => {
      setLogo(data.home || bg);
    });
  }, []);

  return (
    <>
      {/* HEADER (SOLO MOVIL) */}
      <Box sx={styles.barramovil}>
        <img src={"/assets/clipboard4.svg"} alt="Radar Freim" />
      </Box>
      <Grid container sx={styles.root}>
        {/* VISTA IZQUIERDA */}
        <Grid
          item
          xs={false}
          md={7}
          sx={{
            ...styles.image,
            backgroundImage: `url(${logo})`,
          }}
        />
        {/* VISTA DERECHA */}
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          md={5}
          mt={2}
          px={4}
        >
          <Grid item justifyContent="center" display="flex">
            <img alt="logo" src={"/assets/logo.svg"} height="120px" />
          </Grid>
          <Grid item mt={2}>
            <Typography sx={styles.title}>Acceder</Typography>
          </Grid>
          <Box component="form" role="form" onSubmit={handleSignin}>
            <Grid container item direction="column">
              <Grid item mb={2} justifyContent="center" display="flex">
                {errorApi && (
                  <Alert sx={{ width: "100%" }} severity="error">
                    {errorApi}
                  </Alert>
                )}
              </Grid>
              <Grid item justifyContent="center" display="flex">
                <TextField
                  color="warning"
                  variant="outlined"
                  fullWidth
                  label="Correo Electronico*"
                  value={form.username}
                  onChange={(e) =>
                    setForm({ ...form, username: e.target.value })
                  }
                  name="email"
                  type="email"
                />
              </Grid>
            </Grid>
            <Grid container item direction="column" mt={2}>
              <Grid item justifyContent="center" display="flex">
                <TextField
                  fullWidth
                  color="warning"
                  variant="outlined"
                  label="Contraseña*"
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  name="password"
                  type="password"
                  id="password"
                />
              </Grid>
            </Grid>
            <Grid item mt={1} display="flex" justifyContent="end" mr={3}>
              <Link to="/recover-password">
                <Typography sx={{ textDecoration: "underline" }}>
                  ¿Has olvidado tu contraseña?
                </Typography>
              </Link>
            </Grid>
            <Grid item mt={3} display="flex" justifyContent="center">
              <LoadingButton
                loading={loadingApi.includes("post__login")}
                variant="contained"
                type="submit"
                sx={styles.button}
              >
                Iniciar sesión
              </LoadingButton>
            </Grid>
          </Box>
          <Stack direction="row" spacing={1} justifyContent="center" mt={2}>
            <Typography> ¿Aún no tienes cuenta? </Typography>
            <Link to="/register">
              <Typography color="primary" sx={{ textDecoration: "underline" }}>
                Regístrate
              </Typography>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
