import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import styles from "../../components/globalstyles";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../hooks/useUser";
import handleInput from "../../utils/handleInput";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import validator from "validator";
import bg from "../../assets/wallpaper-login.webp";

//style modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: 300, sm: 500 },
  maxHeight: 500,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  "::-webkit-scrollbar": {
    width: 0,
  },
};

const Register = () => {
  const [form, setForm] = useState({ role: "artista" });
  const [terminos, setTerminos] = useState(false);
  const [open, setOpen] = useState(false);
  const [faqs, setFaqs] = useState(false);
  const [logo, setLogo] = useState(null);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { loadApi, loadingApi, errorApi } = useApi();
  const { setUser } = useUser();
  const navigate = useNavigate();

  const changeForm = (name) => {
    return handleInput(name, setForm);
  };

  function handleSignin(e) {
    e.preventDefault();

    if (!(form.name && form.username && form.password && form.password2)) {
      showSnack("Debes completar todos los campos", "warning");
      return;
    }

    if (!validator.isAlphanumeric(form.name?.replace(/_/g, ""), "en-US")) {
      showSnack(
        "El usuario solo puede contener letras, numeros y _",
        "warning"
      );
      return;
    }
    if (!validator.isEmail(form.username)) {
      showSnack("El correo electrónico no es válido", "warning");
      return;
    }
    if (form.password.length < 8) {
      showSnack(
        "La contraseña debe contener por lo menos 8 caracteres",
        "warning"
      );
      return;
    }

    if (form.password !== form.password2) {
      showSnack("Las contraseñas deben coincidir", "warning");
      return;
    }

    setForm((data) => {
      if (data.name) data["name"] = data.name.toLowerCase();
      if (data.username) data["username"] = data.username.toLowerCase();
      return data;
    });

    loadApi("register", false, "post", form)
      .then((response) => {
        navigate("/success?type=verify_email");
      })
      .catch((e) => {});
  }

  //close modal
  const handleClose = () => {
    setOpen(false);
  };

  //Traer mensaje de compartir
  useEffect(() => {
    loadApi("general/parametros", false, "get").then(({ data }) => {
      setFaqs(data.terminos);
      setLogo(data.home || bg);
    });
  }, []);

  return (
    <>
      {/* HEADER (SOLO MOVIL) */}
      <Box sx={styles.barramovil}>
        <img src={"/assets/clipboard4.svg"} alt="Radar Freim" />
      </Box>

      <Grid container sx={styles.root}>
        {/* VISTA IZQUIERDA */}
        <Grid
          item
          md={7}
          sx={{
            ...styles.image,
            backgroundImage: `url(${logo})`,
          }}
        >
          <img alt="Radar Freim" src={"/assets/logo.svg"} width="40%" />
        </Grid>
        {/* VISTA DERECHA */}
        <Grid container item md={5} px={{ xs: 4, lg: 7 }}>
          <Stack
            component="form"
            role="form"
            spacing={2}
            width="100%"
            onSubmit={handleSignin}
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ display: { xs: "inherit", lg: "none" } }}>
              <img alt="logo" src={"/assets/logo.svg"} height="80px" />
            </Box>
            <Stack direction="row" justifyContent="left" width="100%">
              <Typography sx={styles.title} align="left">
                Registro
              </Typography>
            </Stack>
            <Stack spacing={1.5} width="100%">
              {errorApi && (
                <Alert sx={{ mb: 2 }} severity="error">
                  {errorApi}
                </Alert>
              )}
              <TextField
                fullWidth
                hiddenLabel
                placeholder="tunombre123_"
                value={form.name || ""}
                onChange={changeForm("name")}
                label="Usuario"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">@</InputAdornment>
                  ),
                }}
              />
              <TextField
                color="warning"
                variant="outlined"
                fullWidth
                label="Correo Electronico*"
                value={form.username}
                onChange={changeForm("username")}
                type="email"
              />
              <TextField
                fullWidth
                color="warning"
                variant="outlined"
                label="Contraseña*"
                onChange={changeForm("password")}
                type="password"
              />
              <TextField
                fullWidth
                color="warning"
                variant="outlined"
                label="Repite Contraseña*"
                onChange={changeForm("password2")}
                type="password"
              />
              <FormControl fullWidth>
                <InputLabel id="rolLabel">Soy:</InputLabel>
                <Select
                  labelId="rolLabel"
                  label="Rol*"
                  defaultValue="artista"
                  onChange={changeForm("role")}
                >
                  <MenuItem
                    value="artista"
                    sx={{ fontWeight: 600, color: "#5c5c5c" }}
                  >
                    Artista
                  </MenuItem>
                  <MenuItem
                    value="productor"
                    sx={{ fontWeight: 600, color: "#5c5c5c" }}
                  >
                    Productor
                  </MenuItem>
                </Select>
              </FormControl>
              <Stack direction="row" alignItems="center">
                <Checkbox onChange={(e) => setTerminos(e.target.checked)} />
                <label>
                  Aceptar{" "}
                  <Link
                    onClick={() => setOpen(true)}
                    style={{ color: "#F27224" }}
                  >
                    terminos y condiciones.
                  </Link>{" "}
                </label>
              </Stack>
            </Stack>
            <LoadingButton
              loading={loadingApi.includes("post__register")}
              variant="contained"
              type="submit"
              sx={styles.button}
              disabled={!terminos}
            >
              REGISTRARME
            </LoadingButton>
            <Link to="/login">
              <Typography color="primary" sx={{ textDecoration: "underline" }}>
                YA TENGO CUENTA
              </Typography>
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" textAlign="center">
            Terminos y condiciones
          </Typography>
          <Box dangerouslySetInnerHTML={{ __html: faqs }} />
        </Box>
      </Modal>
    </>
  );
};

export default Register;
