import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Fade,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import { useUser } from "../../../hooks/useUser";
import styled from "@emotion/styled";
import portada from "../../../assets/portadaProductor2.jpg";
import { LoadingButton } from "@mui/lab";
//Hooks
import useApi from "../../../hooks/useApi";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
//Icons
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import countries from "../../../utils/countries";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  FaSpotify,
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useNavigate } from "react-router-dom";

//Custom styled
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const stats = [
  { name: "Aceptadas", value: 0, tooltip: "Canciones" },
  { name: "Rechazadas", value: 0, tooltip: "Canciones" },
  { name: "Calificaion", value: 0, tooltip: "Calificación" },
];

const contactInit = [
  { Icon: EmailOutlinedIcon, value: "", name: "email" },
  { Icon: PlaceOutlinedIcon, value: "", name: "location" },
];

const socialNetworkInit = [
  { name: "facebook", value: "", Icon: FaFacebook },
  { name: "instagram", value: "", Icon: FaInstagram },
  { name: "spotify", value: "", Icon: FaSpotify },
  { name: "youtube", value: "", Icon: FaYoutube },
  { name: "tiktok", value: "", Icon: FaTiktok },
];

const ArtistProfile = () => {
  const { user, updateUser } = useUser();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const navigate = useNavigate();
  //states
  const [onHoverImg, setOnHoverImg] = useState(false);
  const [statState, setStatState] = useState(stats);
  const [previewImage, setPreviewImage] = useState(null);
  const [contact, setContact] = useState(contactInit);
  const [socialNetwork, setSocialNetwork] = useState(socialNetworkInit);
  const [songs, setSongs] = useState(0);
  useEffect(() => {
    setContact((adata) => [
      { ...adata[0], value: user?.profile?.email_contacto },
      { ...adata[1], value: user?.profile?.pais },
    ]);

    setStatState((adata) => {
      for (let item of adata) {
        if (item.name === "Calificaion")
          item.value = user?.profile?.rating_prom;
      }
      return adata;
    });

    setSocialNetwork((adata) => [
      { ...adata[0], value: user?.profile?.facebook },
      { ...adata[1], value: user?.profile?.instagram },
      { ...adata[2], value: user?.profile?.spotify },
      { ...adata[3], value: user?.profile?.youtube },
      { ...adata[4], value: user?.profile?.tiktok },
    ]);
    getSongs();
  }, []);

  const handleChangeImg = async ({ target }) => {
    const [file] = target.files;
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      try {
        const { data } = await loadApi(
          "productor/profile",
          true,
          "put",
          { profileimg: file },
          true
        );
        console.log(data);
        updateUser();
        showSnack(data.message);
      } catch (error) {
        showSnack(error.message, "error");
      }
    } else {
      setPreviewImage(null);
    }
  };

  const getSongs = async () => {
    try {
      const { data } = await loadApi("artista/canciones", true, "get");
      setSongs(data.length);
    } catch (error) {
      setSongs(0);
    }
  };

  return (
    <Stack bgcolor="#f0f1f7" minHeight="100vh" pb={5}>
      <GlobalHeader />
      <Container maxWidth="md">
        <Stack
          bgcolor="#ffffff"
          sx={{
            borderRadius: "20px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          {/* Card profile */}
          <Stack
            sx={{
              background: {
                xs: `center center/cover no-repeat url(${portada})`,
                sm: `center -50px/cover no-repeat url(${portada})`,
              },
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            borderRadius={4}
          >
            {/* header */}
            <Stack
              py={3}
              px={{ xs: 2, sm: 4 }}
              borderRadius={4}
              direction={{ xs: "column", sm: "row" }}
              gap={2}
              sx={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              component="header"
            >
              {/* profile img */}
              <Box>
                <LoadingButton
                  component="label"
                  variant="text"
                  sx={{
                    padding: 0,
                    borderRadius: "50%",
                  }}
                  onMouseEnter={() => setOnHoverImg(true)}
                  onMouseLeave={() => setOnHoverImg(false)}
                >
                  {/* input file */}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleChangeImg}
                    accept="image/*"
                  />
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar
                      alt="profile_img"
                      src={previewImage || user?.profile?.profileimg}
                      sx={{
                        width: "80px",
                        height: "80px",
                      }}
                    />
                  </StyledBadge>
                  {/* hover */}
                  <Fade in={onHoverImg}>
                    <Stack
                      sx={{
                        backgroundColor: "rgba(0,0,0,.3)",
                        position: "absolute",
                        zIndex: 10,
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CameraAltOutlinedIcon
                        color="secondary"
                        fontSize="large"
                      />
                    </Stack>
                  </Fade>
                </LoadingButton>
              </Box>
              {/* Name and info */}
              <Stack width="100%">
                <Stack
                  direction="row"
                  gap={2}
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  <Typography
                    variant="h6"
                    color="white"
                    sx={{
                      width: { sm: "100%" },
                      maxWidth: { sm: "200px" },
                      textWrap: { sm: "nowrap" },
                    }}
                  >
                    {user?.profile?.nombre_media}
                  </Typography>
                  <Button
                    sx={{ borderRadius: 2, px: 1.5, textTransform: "none" }}
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("./edit_profile")}
                  >
                    Editar
                  </Button>
                </Stack>
                <Stack direction="row" gap={3}>
                  <Stack direction="row" color="#D7CCEF" alignItems="center">
                    <HeadphonesIcon fontSize="small" />
                    <Typography variant="caption" color="#D7CCEF">
                      {user?.profile?.tipo}
                    </Typography>
                  </Stack>
                  <Stack direction="row" color="#D7CCEF" alignItems="center">
                    <PlaceOutlinedIcon fontSize="small" />
                    <Typography variant="caption" color="#D7CCEF">
                      {countries[user?.profile?.pais]}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack mt={3} direction="row" gap={2}>
                  <Stack sx={{ userSelect: "none", cursor: "default" }}>
                    <Typography variant="h4" color="white">
                      {songs}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="#D7CCEF"
                      sx={{
                        maxWidth: "100px",
                      }}
                    >
                      Canciones
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* Description */}
          <Stack component="section" px={{ xs: 1, sm: 2 }} py={4}>
            <Typography variant="subtitle1">Descripción personal:</Typography>
            <Typography variant="caption" color="GrayText">
              {user?.profile?.descripcion}
            </Typography>
            <Link href={user?.profile?.pagina_web || ""} target="_blank">
              {user?.profile?.pagina_web || ""}
            </Link>
          </Stack>
          <Divider />
          {/* contact info */}
          <Stack px={{ xs: 1, sm: 2 }} py={4} component="section">
            <Typography variant="subtitle1">Información personal:</Typography>
            <Stack gap={1}>
              {contact.map(({ Icon, value, name }, i) => (
                <Stack key={i} direction="row" alignItems="center" gap={1}>
                  <Stack
                    sx={{
                      width: "30px",
                      height: "30px",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      bgcolor: "#f3f6f8",
                      color: "#6d6d6d",
                    }}
                  >
                    <Icon fontSize="small" />
                  </Stack>
                  <Typography variant="caption" color="grayText">
                    {name === "location" ? countries[value] : value || "- - -"}
                  </Typography>
                  {i === 1 && (
                    <Box
                      component="img"
                      src={`https://flagcdn.com/${user.profile.pais}.svg`}
                      alt="País"
                      width="20px"
                      height="15px"
                    />
                  )}
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Divider />
          {/* Social network */}
          <Stack
            px={{ xs: 1, sm: 2 }}
            py={4}
            component="section"
            direction="row"
            gap={2}
          >
            <Typography variant="subtitle1">Redes sociales: </Typography>
            <Stack gap={1} direction="row">
              {socialNetwork.map(({ value, Icon }, i) => (
                <Button
                  key={i}
                  sx={{
                    py: 1,
                    px: 1,
                    minWidth: "auto",
                    ":hover": {
                      color: "#ffffff",
                      bgcolor: "#F27224",
                    },
                  }}
                  variant="outlined"
                  href={value}
                  target="_blank"
                  rel="nofollow"
                >
                  <Icon />
                </Button>
              ))}
            </Stack>
          </Stack>
          <Divider />
          {/* musical gender */}
          <Stack px={{ xs: 1, sm: 2 }} py={4} component="section" gap={2}>
            <Typography variant="subtitle1">Generos musicales: </Typography>
            <Stack gap={1} direction="row">
              <Chip label={user?.profile?.generos_musicales} size="small" />
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </Stack>
  );
};

export default ArtistProfile;
