import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import GlobalHeader from "../../../components/GlobalHeader";
import { useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { useState } from "react";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import styled from "@emotion/styled";

//icons
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";

//modulos faqs
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { ident: "-1" }, { ident: "+1" }],
    ["link"],
  ],
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Personalizar = () => {
  const { loadApi, loadingApi } = useApi();
  const [data, setData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImageHome, setPreviewImageHome] = useState(null);
  const [editData, setEditData] = useState({});
  const { msgSB, openSB, showSnack, typeSB } = useSnack();

  const [area, setArea] = useState("");
  const [terminos, setTerminos] = useState("");

  useEffect(() => {
    loadApi("admin/parametros", true, "get")
      .then((res) => {
        setData(res.data);
        setArea(res.data.faqs);
        setTerminos(res.data.terminos);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      });
  }, []);

  //añadir archivo de logo al form
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    const { name } = e.target;
    if (selectedImage) {
      if (name === "logo") {
        setPreviewImage(URL.createObjectURL(selectedImage));
      } else {
        setPreviewImageHome(URL.createObjectURL(selectedImage));
      }
      setEditData((adata) => ({ ...adata, [name]: selectedImage }));
    } else {
      if (name === "logo") {
        setPreviewImage(null);
      } else {
        setPreviewImageHome(null);
      }
    }
  };

  //update
  const handleSubmit = () => {
    let adata = editData;
    if (area) adata = { ...adata, faqs: area };
    if (terminos) adata = { ...adata, terminos: terminos };
    loadApi("admin/parametros", true, "put", adata, true)
      .then((res) => {
        showSnack(res.data.message);
      })
      .catch((e) => {
        showSnack(e.message, "error");
      });
  };

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack sx={{ gap: "2rem" }} pb={4}>
          <Typography variant="h5">Logo de la pagina</Typography>
          {/* image logo */}
          <Stack>
            <img
              src={previewImage || data.logo}
              style={{ width: "200px", height: "200px", objectFit: "cover" }}
            />
            <LoadingButton
              component="label"
              variant="outlined"
              sx={{
                transition: "opacity 0.3s",
                borderRadius: "4px",
                backgroundColor: "white",
                backgroundSize: "cover",
                minWidth: "0%",
                width: "30px!important",
                height: "30px!important",
                position: "absolute",
                marginLeft: "185px",
                marginTop: "-7px",
                zIndex: "10",
                padding: "0",
                color: "#666",
                borderColor: "#666",
                borderRadius: "50%",
                ":hover": {
                  backgroundColor: "white",
                  borderColor: "#666",
                  transform: "scale(1.02)",
                },
              }}
            >
              <AddAPhotoIcon sx={{ width: "18px" }} />
              <input
                type="file"
                onChange={handleImageChange}
                style={{ width: "100%", height: "100%" }}
                hidden
                accept=".png, .jpg, .jpeg, .webp"
                name="logo"
              />
            </LoadingButton>
          </Stack>
          {/* Personalizar imagen de inicio */}
          <Stack alignItems="flex-start">
            <Typography variant="h5">
              Personalizar imagen de inicio/Login
            </Typography>
            <Box width="100%" maxWidth="600px">
              <Box
                component="img"
                src={previewImageHome || data?.home}
                width="100%"
              />
            </Box>
            <Button
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{ mt: 1 }}
            >
              <VisuallyHiddenInput
                type="file"
                accept=".jpg, .png, .jpeg, .webp"
                name="home"
                onChange={handleImageChange}
              />
              Adjuntar
            </Button>
          </Stack>
          <Stack>
            <Typography variant="h5" mb={2}>
              Personalizar mensage de compartir
            </Typography>
            <TextField
              variant="outlined"
              defaultValue={data.sharedMessage}
              onChange={(e) =>
                setEditData((adata) => ({
                  ...adata,
                  sharedMessage: e.target.value,
                }))
              }
              multiline
              rows={4}
              sx={{ width: "100%" }}
              placeholder="Escriba aqui..."
            ></TextField>
          </Stack>
          <Stack height="300px">
            <Typography variant="h5" mb={2}>
              Personalizar los Faqs
            </Typography>
            <Box>
              <ReactQuill
                value={area}
                theme="snow"
                onChange={setArea}
                modules={modules}
              />
            </Box>
          </Stack>
          <Stack>
            <Typography variant="h5" mb={2}>
              Personalizar notificación de apruebo
            </Typography>
            <TextField
              multiline
              variant="outlined"
              defaultValue={data.notification_approve}
              onChange={(e) =>
                setEditData((adata) => ({
                  ...adata,
                  notification_approve: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
              placeholder="Escriba aqui..."
            ></TextField>
          </Stack>
          <Stack>
            <Typography variant="h5" mb={2}>
              Personalizar notificación de rechazado
            </Typography>
            <TextField
              multiline
              variant="outlined"
              defaultValue={data.notification_rejected}
              onChange={(e) =>
                setEditData((adata) => ({
                  ...adata,
                  notification_rejected: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
              placeholder="Escriba aqui..."
            ></TextField>
          </Stack>
          <Stack>
            <Typography variant="h5" mb={2}>
              Personalizar notificación de envío de canción artista
            </Typography>
            <TextField
              multiline
              variant="outlined"
              defaultValue={data.notification_envio_cancion}
              onChange={(e) =>
                setEditData((adata) => ({
                  ...adata,
                  notification_envio_cancion: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
              placeholder="Escriba aqui..."
            ></TextField>
          </Stack>
          <Stack>
            <Typography variant="h5" mb={2}>
              Personalizar notificación de envío de canción productor
            </Typography>
            <TextField
              multiline
              variant="outlined"
              defaultValue={data.notification_envio_cancion_productor}
              onChange={(e) =>
                setEditData((adata) => ({
                  ...adata,
                  notification_envio_cancion_productor: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
              placeholder="Escriba aqui..."
            ></TextField>
          </Stack>
          <Stack>
            <Typography variant="h5" mb={2}>
              Personalizar los terminos y condiciones
            </Typography>
            <Box>
              <ReactQuill
                value={terminos}
                theme="snow"
                onChange={setTerminos}
                modules={modules}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              loading={loadingApi.includes("put__admin/parametros")}
              sx={{ px: "28px" }}
              onClick={handleSubmit}
            >
              Guardar
            </LoadingButton>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default Personalizar;
