import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { Audio } from "react-loading-icons";
import countries from "../../../utils/countries";
import getCurrentDate from "../../../utils/getCurrentDate";
import youtubeToEmbed from "../../../utils/youtubeToEmbed";
import spotifyToEmbed from "../../../utils/spotifyToEmbed";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

export default function VistaADashboard() {
  const { loadApi, loadingApi } = useApi();
  const { songId } = useParams();
  const { user } = useUser();
  const [mainData, setMainData] = useState(false);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const navigate = useNavigate();

  useEffect(() => {
    loadApi(`artista/canciones/${songId}`, true, "get")
      .then((response) => {
        setMainData(response.data || false);
      })
      .catch((e) => {
        showSnack(e.message, "error", false);
      });
  }, []);

  const handleDelete = (songId) => {
    console.log(songId);
    Swal.fire({
      icon: "question",
      text: "¿ Seguro que desea Eliminar esta canción ?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "#F27224",
    }).then((con) => {
      if (con.isConfirmed)
        loadApi(`artista/canciones/${songId}`, true, "delete")
          .then((res) => {
            showSnack(res.data.message);
            setTimeout(() => {
              navigate("/artista/biblioteca");
            }, 1000);
          })
          .catch((e) => {
            showSnack(e.message, "error", false);
          });
    });
  };

  return (
    <>
      <GlobalHeader />
      {!mainData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: `calc(100vh - ${
              window.document.getElementById("GlobalHeader")?.offsetHeight ||
              "64"
            }px - 32px)`,
          }}
        >
          <Audio fill="#F27224" />
        </Box>
      ) : (
        <Container>
          <Grid container spacing={2} mb={5}>
            {/* COLUMNA IZQUIERDA */}
            <Grid item xs={12} md={7} lg={7}>
              <Stack spacing={2}>
                <Typography
                  variant="h4"
                  sx={{
                    paddingBottom: 1,
                    borderBottom: "2px solid gray",
                  }}
                >
                  Preview de tu canción
                </Typography>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Box sx={{ width: { xs: "100%", lg: "93%" } }}>
                      <img
                        src={mainData.url_img}
                        style={{
                          borderRadius: "4px/6.7px",
                          objectFit: "cover",
                        }}
                        width="100%"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={1}>
                      <Stack direction="row" flexWrap="wrap">
                        <Typography variant="h6">
                          {user.profile.nombre_media} - "{mainData.nombre}"
                        </Typography>
                      </Stack>
                      <Typography variant="p">
                        Fecha de envio: {getCurrentDate()}
                      </Typography>
                      <Typography variant="p">
                        País: {countries[mainData.pais]}
                      </Typography>
                      <Typography variant="p">
                        Género: {mainData.genero}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {/* <LoadingButton onClick={() => handleSpotify()}>SPOTIFY</LoadingButton> */}
                <Typography variant="h5">Descripción de la canción</Typography>
                <Typography variant="p">
                  <pre
                    style={{
                      fontFamily: "'Roboto', Helvetica, Arial",
                      fontWeight: "400",
                    }}
                  >
                    {mainData.descripcion}
                  </pre>
                </Typography>
                <Typography variant="h5">Perfil</Typography>
                <Typography variant="p">{user.profile.descripcion}</Typography>
              </Stack>
            </Grid>
            {/* COLUMNA DERECHA */}
            <Grid item xs={12} md={5} lg={5}>
              <Stack spacing={3} px={3}>
                {/* <img
                    src={mainData.url_img}
                    style={{ borderRadius: "4px/6.7px" }}
                    width="70%"
                  /> */}
                {youtubeToEmbed(mainData.url) ? (
                  <Box
                    id={`iframePrincipal${mainData.id}`}
                    width="100%"
                    sx={{
                      iframe: {
                        borderRadius: "4px/6.7px",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        left: "0",
                        bottom: "0",
                      },
                      position: "relative",
                      width: "100%",
                      pb: "56.25%",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={youtubeToEmbed(mainData.url)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                ) : spotifyToEmbed(mainData.url) ? (
                  <Box
                    id={`iframePrincipal${mainData.id}`}
                    width="100%"
                    sx={{
                      iframe: {
                        borderRadius: "4px/6.7px",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        left: "0",
                        bottom: "0",
                      },
                      position: "relative",
                      width: "100%",
                      pb: "56.25%",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={spotifyToEmbed(mainData.url)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                ) : (
                  <Box
                    id={`iframePrincipal${mainData.id}`}
                    width="100%"
                    sx={{
                      iframe: {
                        borderRadius: "4px/6.7px",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        left: "0",
                        bottom: "0",
                      },
                      position: "relative",
                      width: "100%",
                      pb: "56.25%",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={mainData.url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                )}
                {/* <Stack
                  direction="row"
                  spacing={1.3}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" align="left">
                    Obtener codigo embed
                  </Typography>
                  <Button color="info">Copiar</Button>
                </Stack> */}
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{
                    borderBottom: "2px dashed gray",
                  }}
                ></Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Button
                    LinkComponent={Link}
                    to={`/artista/enviar/${songId}/`}
                    sx={{ textTransform: "none", borderRadius: 2 }}
                  >
                    ¡Enviar mi canción!
                  </Button>
                  <Stack direction="row" spacing={1}>
                    <Button
                      LinkComponent={Link}
                      to={`/artista/nueva?edit=${songId}`}
                      sx={{
                        textTransform: "none",
                        borderRadius: 2,
                        minWidth: "auto",
                      }}
                      color="primary"
                    >
                      <EditIcon />
                    </Button>
                    <LoadingButton
                      color="error"
                      loading={loadingApi.includes(
                        `delete__artista/canciones/${songId}`
                      )}
                      onClick={() => handleDelete(songId)}
                      sx={{ textTransform: "none", borderRadius: 2 }}
                    >
                      Eliminar
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      )}
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
