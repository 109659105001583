// Pages
import ViewArtistSongs from "./pages/artista/canciones";
import ViewArtistInfluencers from "./pages/artista/influencers";
import ViewArtistUploadSong from "./pages/artista/subirCancion";
import ViewArtistSong from "./pages/artista/cancion";
import ViewArtistSends from "./pages/artista/envios";
import Login from "./pages/auth/login";
import Error from "./pages/messages/error";
import Sucess from "./pages/messages/success";
import Register from "./pages/auth/register";
import Logout from "./pages/auth/logout";
import RecoverPassword from "./pages/auth/recover-password";
import ResetPassword from "./pages/auth/reset-password";
import ViewCompleteProfile from "./pages/completeProfile";
import ViewProductorHistory from "./pages/productor/historial";
import ViewProductorSends from "./pages/productor/envios";
import ViewProductorSong from "./pages/productor/cancion";
import Ventanadialogo from "./pages/artista/dashboard/ventanadialogo";
import VistaEnvio from "./pages/artista/dashboard/vistaenvio";
import ViewProfile from "./pages/artista/perfil";
import ProfileProductor from "./pages/productor/perfil";
import Faqs from "./pages/faqs";
import TiendaArtista from "./pages/artista/tienda";
import UsersAdmin from "./pages/admin/ProductoresAndMusicos";
import CancionesAdmin from "./pages/admin/cancionesPendientes";
import BoletinAdmin from "./pages/admin/boletin";
import RetirosAdmin from "./pages/admin/retiros";
import Scrollamatest from "./pages/productor/testscrollama";
import RetirosProductor from "./pages/productor/retiros";
import Embed from "./pages/embed";
import HistorialRetiros from "./pages/admin/historialRetiros";
import Personalizar from "./pages/admin/personalizar";
import Dashboard from "./pages/admin/dashboard";
import Bonos from "./pages/admin/bonos";
import Vouchers from "./pages/artista/bonos";
import ProductorProfile from "./pages/productor/perfil/Profile";
import EditProfileProducer from "./pages/productor/perfil/editProfile";
import ArtistProfile from "./pages/artista/perfil/Profile";
import EditProfileArtist from "./pages/artista/perfil/editProfile";
const routes = [
  {
    route: "/login",
    role: "auth",
    component: <Login />,
  },
  {
    route: "/logout",
    component: <Logout />,
  },
  {
    route: "/register",
    role: "auth",
    component: <Register />,
  },
  {
    route: "/recover-password",
    role: "auth",
    component: <RecoverPassword />,
  },
  {
    route: "/reset-password",
    role: "auth",
    component: <ResetPassword />,
  },
  {
    route: "/error",
    component: <Error />,
  },
  {
    route: "/success",
    component: <Sucess />,
  },
  {
    route: "/embed/:songid",
    component: <Embed />,
  },
  {
    route: "/complete-profile",
    role: ["artista", "productor"],
    component: <ViewCompleteProfile />,
  },
  {
    route: "/faqs",
    role: ["artista", "productor"],
    component: <Faqs />,
  },
  //--------------ARTISTA ROUTES----------------
  {
    route: "/artista/perfil",
    role: "artista",
    component: <ArtistProfile />,
  },
  {
    route: "/artista/perfil/edit_profile",
    role: "artista",
    component: <EditProfileArtist />,
  },
  // {
  //   route: "/artista/perfil",
  //   role: "artista",
  //   component: <ViewProfile />,
  // },
  {
    route: "/artista/biblioteca",
    role: "artista",
    component: <ViewArtistSongs />,
  },
  {
    route: "/artista/nueva",
    role: "artista",
    component: <ViewArtistUploadSong />,
  },
  {
    route: "/artista/envios",
    role: "artista",
    component: <ViewArtistSends />,
  },
  {
    route: "/artista/enviar/:songId/",
    role: "artista",
    component: <ViewArtistInfluencers />,
  },
  {
    route: "/artista/cancion/:songId/",
    role: "artista",
    component: <ViewArtistSong />,
  },
  {
    route: "/artista/tienda",
    role: "artista",
    component: <TiendaArtista />,
  },
  {
    route: "/artista/bonos",
    role: "artista",
    component: <Vouchers />,
  },
  //--------------PRODUCTOR ROUTES----------------
  {
    route: "/productor/cancion/:songId/",
    role: "productor",
    component: <ViewProductorSong />,
  },
  {
    route: "/productor/envios",
    role: "productor",
    component: <ViewProductorSends />,
  },
  {
    route: "/productor/historial",
    role: "productor",
    component: <ViewProductorHistory />,
  },
  {
    route: "/productor/perfil",
    role: "productor",
    component: <ProductorProfile />,
  },
  {
    route: "/productor/perfil/edit_profile",
    role: "productor",
    component: <EditProfileProducer />,
  },
  // {
  //   route: "/productor/perfil",
  //   role: "productor",
  //   component: <ProfileProductor />,
  // },
  {
    route: "/productor/test",
    role: "productor",
    component: <Scrollamatest />,
  },
  {
    route: "/productor/retiros",
    role: "productor",
    component: <RetirosProductor />,
  },
  // -------------------Admin Routes-----------------------------------------
  {
    route: "/admin/users",
    role: "admin",
    component: <UsersAdmin />,
  },
  {
    route: "/admin/users/canciones/:userId",
    role: "admin",
    component: <CancionesAdmin />,
  },
  {
    route: "/admin/boletin",
    role: "admin",
    component: <BoletinAdmin />,
  },
  {
    route: "/admin/retiros",
    role: "admin",
    component: <RetirosAdmin />,
  },
  {
    route: "/admin/historial_retiros",
    role: "admin",
    component: <HistorialRetiros />,
  },
  {
    route: "/admin/personalizar",
    role: "admin",
    component: <Personalizar />,
  },
  {
    route: "/admin/dashboard",
    role: "admin",
    component: <Dashboard />,
  },
  {
    route: "/admin/bonos",
    role: "admin",
    component: <Bonos />,
  },
];

export default routes;
