import React, { useEffect, useState } from "react";
import GlobalHeader from "../../components/GlobalHeader";
import { Box, Container, Stack, Typography } from "@mui/material";
import useApi from "../../hooks/useApi";

const Faqs = () => {
  const { loadApi } = useApi()
  const [ faqs, setFaqs ] = useState("")
  //Traer mensaje de compartir
  useEffect(() => {
    loadApi("general/parametros", false, "get").then((res) => {
      setFaqs(res.data.faqs);
    });
  }, []);

  return (
    <>
      <GlobalHeader />
      <Container sx={{ pb: 4, pt: 4 }}>
        {/* <Stack spacing={5}>
          <Stack spacing={2} >
            <Typography variant="h4">
              Qué es y para qué sirve #RadarFreim
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              #RadarFreim es la plataforma que conecta a los músicos con blogs,
              estaciones de radio, revistas y periódicos digitales enfocados a
              la música. <br />
              <br /> A través del uso de créditos el artista podrá enviar su
              canción a los productores que él prefiera, a su vez, los
              productores deberán escuchar la canción por un lapso mínimo de 30
              segundos.
            </Typography>
          </Stack>
          <Stack spacing={2} >
            <Typography variant="h4">
              ¿Qué pasa cuando el productor acepta la canción?
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              Si después de escuchar el tema, el productor decide compartirla,
              entonces tendrá un lapso de 10 días para hacerlo y deberá
              notificarle al artista cuando lo haya realizado a través del envío
              del link. <br />
              <br /> Cuando la canción haya sido compartida y el link haya sido
              enviado al artista, en ese momento el productor podrá cobrar su
              crédito.
            </Typography>
          </Stack>
          <Stack spacing={2} >
            <Typography variant="h4">
              ¿Y si el productor no acepta la canción?
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              Si el productor decide que no le gustó la canción y no la
              comparte, entonces deberá darle una razón al artista del porqué y
              no podrá cobrar el crédito, mismo que sería regresado a la cuenta
              del artista para que así pueda probar suerte con otro productor.
            </Typography>
          </Stack>
          <Stack spacing={2} >
            <Typography variant="h4">
              ¿Qué precio tienen los créditos?
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              El crédito tiene un costo de 2 USD, y mientras más créditos
              compres, el precio irá bajando. La compra puedes hacerla con
              tarjetas de crédito / débito y directamente con una cuenta paypal.
            </Typography>
          </Stack>
          <Stack spacing={2} >
            <Typography variant="h4">
              ¿Cuántos créditos se necesitan para enviar una canción?
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              Un crédito equivale a un envío para redes sociales, si el artista
              quiere enviarle la misma canción a 10 productores diferentes,
              entonces tendrá que gastar 10 créditos. Sabemos que algunas veces
              los productores van a rechazar la canción, pero aun así
              garantizamos el 80% de aceptación en los envíos. <br />
              <br /> Estamos conscientes de que el artista debe invertir para
              que su música llegue a los oídos correctos y #RadarFreim es la
              mejor opción para ello, ya que es una plataforma organizada y muy
              accesible en cuanto a precios, muchísimo más barato que contratar
              a un RP o publicista.
            </Typography>
          </Stack>
          <Stack spacing={2} >
            <Typography variant="h4">
              ¿Qué pasa si quiero una entrevista o una nota editorial?
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              Si el músico quiere una entrevista o un post dedicado sólo a él y
              su proyecto, entonces el costo será de 5 créditos, esto
              simplemente por la razón de que para escribir un texto o realizar
              una entrevista se necesita tiempo y más esfuerzo que simplemente
              compartir la canción en redes sociales. En #RadarFreim pensamos
              que el tiempo y el trabajo del músico y de los productores merecen
              el mismo valor y reconocimiento.
            </Typography>
          </Stack>
          <Stack spacing={2} >
            <Typography variant="h4">
              ¿Cómo cobra un productor sus créditos?
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              A través de una petición dentro de la plataforma y el pago se hará
              manualmente a su cuenta paypal, el mínimo para pedir un cobro es
              de 25 USD.
            </Typography>
          </Stack>
          <Stack spacing={2} >
            <Typography variant="h4">
              Precio de los Créditos o freimcoins:
            </Typography>
            <Typography variant="p" px={{ xs: 0, sm: 4 }}>
              5 freimcoins $10 USD ($2 USD el crédito) <br />
              <br />
              10 freimcoins $19 USD ($1.90 USD el crédito) <br />
              <br />
              30 freimcoins $54 USD ($1.80 USD el crédito) <br />
              <br /> Paquete Profesional (ideal para disqueras y RPs)
              120 freimcoins <br/><br/> Nuestro paquete profesional consta de 120
              freimcoins mensuales para que puedas usarlos cuando quieras y son
              acumulativos. Tiene un costo de $180 USD, por lo que cada crédito
              cuesta $1.50 USD. <br/><br/>  Y si llegarás a necesitar más, puedes adquirir
              los que te falten al mismo precio unitario.
            </Typography>
          </Stack>
        </Stack> */}
        <Typography variant="subtitle1" fontWeight={500}>
          <Box dangerouslySetInnerHTML={{ __html: faqs }} />
          <pre
            style={{
              fontFamily: "'Roboto', Helvetica, Arial",
              fontWeight: "400",
              wordWrap: "break-word",
              textWrap: "wrap"
            }}
          >
          </pre>
        </Typography>
      </Container>
    </>
  );
};

export default Faqs;
