import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Avatar,
  ListItemAvatar,
  Stack,
  Badge,
  Chip,
  Container,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import styles from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AddIcon from "@mui/icons-material/Add";
import Notificaciones from "./notificaciones";
import useApi from "../../hooks/useApi";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

function GlobalHeader() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [noti, setNoti] = useState([]);
  const { loadApi, loadingApi } = useApi();
  const { user } = useUser();
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");
  //Traer logo
  useEffect(() => {
    loadApi("general/parametros", false, "get").then((res) => {
      setLogo(res.data.logo);
    });
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    loadApi("notificaciones", true, "get").then((res) => {
      setNoti(res.data);
    });
  }, []);
  var opciones = [
    {
      title: "SALIR",
      route: "/logout",
    },
  ];

  if (user.profile !== null) {
    if (user.role === "artista") {
      opciones = [
        {
          title: "PERFIL",
          route: "/artista/perfil",
        },
        {
          title: "CANCIONES",
          route: "/artista/biblioteca",
        },
        {
          title: "ENVÍOS",
          route: "/artista/envios",
        },
        {
          title: "FAQS",
          route: "/faqs",
        },
        {
          title: "TIENDA",
          route: "/artista/tienda",
        },
        {
          title: "BONOS DE REGALO",
          route: "/artista/bonos",
        },
        {
          title: "SALIR",
          route: "/logout",
        },
      ];
    }

    if (user.role === "productor") {
      opciones = [
        {
          title: "PERFIL",
          route: "/productor/perfil",
        },
        {
          title: "ENVÍOS PENDIENTES",
          route: "/productor/envios",
        },
        {
          title: "HISTORIAL DE ENVÍOS",
          route: "/productor/historial",
        },
        {
          title: "RETIROS",
          route: "/productor/retiros",
        },
        {
          title: "FAQS",
          route: "/faqs",
        },
        {
          title: "SALIR",
          route: "/logout",
        },
      ];
    }
  } else if (user.role === "admin") {
    opciones = [
      {
        title: "DASHBOARD",
        route: "/admin/dashboard",
      },
      {
        title: "USUARIOS",
        route: "/admin/users",
      },
      {
        title: "RETIROS",
        route: "/admin/retiros",
      },
      {
        title: "HISTORIAL DE RETIROS",
        route: "/admin/historial_retiros",
      },
      {
        title: "PERSONALIZAR PAGINA",
        route: "/admin/personalizar",
      },
      {
        title: "BONOS DE REGALO",
        route: "/admin/bonos",
      },
      {
        title: "SALIR",
        route: "/logout",
      },
    ];
  }

  return (
    <>
      <AppBar id="GlobalHeader" sx={{ marginBottom: 4 }} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <Menu />
          </IconButton>
          <Stack justifyContent="flex-end" direction="row" flexGrow={1}>
            {(user.role === "productor" || user.role === "artista") && (
              <Stack justifyContent="center" mr={2}>
                <Notificaciones noti={noti} setNoti={setNoti} />
              </Stack>
            )}
            {["artista", "productor"].includes(user.role) && (
              <Stack direction="row" alignItems="center" sx={{ gap: "10px" }}>
                <Box
                  sx={{
                    p: "3px 7px",
                    borderRadius: 1,
                    border: ".5px solid #fff",
                    userSelect: "none",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {user.profile?.credits || 0}{" "}
                    {user.role === "artista" ? "RC" : "USD"}
                  </Typography>
                </Box>
              </Stack>
            )}
            {(user.role === "artista" || user.role === "productor") && (
              <Stack ml={2} direction="row" gap={1} alignItems="center">
                <Avatar
                  src={user.profile?.profileimg}
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      user?.role === "artista" && user?.profile
                        ? "/artista/perfil"
                        : "/productor/perfil"
                    )
                  }
                />
                <Stack>
                  <Typography
                    variant="body1"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{
                      maxWidth: "120px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                    }}
                  >
                    {user?.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontSize="12px"
                    fontWeight={400}
                    color="#f1f1f1"
                    mt={-0.3}
                  >
                    {user.role === "artista" ? "Artista" : "Productor"}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box bgcolor="black" px={2} height="100vh">
            <List sx={{ height: "100%", p: 0 }}>
              <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                <Box>
                  <ListItem sx={{ my: 1 }}>
                    <ListItemAvatar>
                      <Avatar src={logo} sx={styles.hlogo} />
                    </ListItemAvatar>
                  </ListItem>
                  {opciones.map((item, i) => (
                    <Link key={i} to={item.route}>
                      <ListItem>
                        <ListItemText
                          primary={item.title}
                          sx={styles.ctopciones}
                        />
                        {item.title === "BONOS DE REGALO" && (
                          <Box ml={2}>
                            <Typography
                              variant="body1"
                              fontWeight={600}
                              color="primary"
                            >
                              new
                            </Typography>
                          </Box>
                        )}
                      </ListItem>
                    </Link>
                  ))}
                </Box>
                <Box>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        src="/assets/social-whatsapp-circle-512.webp"
                        sx={styles.hicono}
                      />
                    </ListItemAvatar>
                  </ListItem>
                </Box>
              </Stack>
            </List>
          </Box>
        </div>
      </Drawer>
    </>
  );
}

export default GlobalHeader;
