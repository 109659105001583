import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { pathToRegexp } from "path-to-regexp";
import useApi from "./useApi";
import main_routes from "../main_routes";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  var userp = {};
  var children2 = children;
  try {
    userp = JSON.parse(localStorage.getItem("user"));
  } catch (e) {}
  const [user, setUserState] = useState(userp);
  const { loggedApi, loadApi, tokenDataApi } = useApi();

  //Hacer petición a update user cada vez que se ejecuta una página
  useEffect(() => {
    updateUser();
  }, []);

  //Función para actualizar user desde cualquier vista
  const setUser = useCallback((newUser) => {
    setUserState(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  }, []);

  // Realizar chequeo de roles, sino existe lo saca del sistema y vuelve a login
  const findMatchingRoute = (routes, currentPath) => {
    for (const route of routes) {
      const regex = pathToRegexp(route.route);
      if (regex.test(currentPath)) {
        return route;
      }
    }
    return null;
  };

  const element = findMatchingRoute(main_routes, window.location.pathname);

  if (element?.role) {
    if (element.role === "auth") {
      if (loggedApi) {
        children2 = false;
        window.location.replace("/");
      }
    } else {
      if (!loggedApi) {
        children2 = false;
        localStorage.clear();
        window.location.replace("/login");
      }
      if (typeof element.role === "string") {
        if (element.role !== tokenDataApi?.role) {
          children2 = false;
          localStorage.clear();
          window.location.replace("/login");
        }
      } else if (typeof element.role === "object") {
        if (!element.role.includes(tokenDataApi?.role)) {
          children2 = false;
          localStorage.clear();
          window.location.replace("/login");
        }
      }
    }
  }

  //Comprobar si aún no ha completado su perfil
  try {
    if (loggedApi) {
      if (user?.profile === null && user?.role !== "admin") {
        if (window.location.pathname !== "/complete-profile") {
          children2 = false;
          window.location.replace("/complete-profile");
        }
      }
    }
  } catch (e) {}

  //actualizar el localstorage.user desde el backend (solo si esta logueado)
  async function updateUser() {
    if (loggedApi) {
      await loadApi("user", true, "get")
        .then((response) => {
          if (response?.data) {
            if (response?.data?.username) {
              setUser(response?.data);
            }
          }
        })
        .catch((e) => {});
    }
  }

  return (
    <UserContext.Provider value={{ user, setUser, updateUser }}>
      {children2}
    </UserContext.Provider>
  );
};
