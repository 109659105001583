import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import GlobalHeader from "../../../components/GlobalHeader";
import { useEffect, useState } from "react";
import handleInput from "../../../utils/handleInput";
import useApi from "../../../hooks/useApi";
import { useLocation, useNavigate } from "react-router-dom";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { LoadingButton } from "@mui/lab";
import generos from "../../../utils/generos";
import { FaSpotify, FaSoundcloud, FaBandcamp, FaYoutube } from "react-icons/fa";
import { soundcloudToEmbed } from "../../../utils/soundcloudToEmbed";
import spotifyToEmbed from "../../../utils/spotifyToEmbed";
import youtubeToEmbed from "../../../utils/youtubeToEmbed";
import { bandcampToEmbed } from "../../../utils/bandcampToEmbed";

const colors = {
  spotify: "#81b71a",
  soundcloud: "#ff7700",
  bandcamp: "#629aa9",
  youtube: " #c4302b",
};

export default function ViewArtistUploadSong() {
  const query = new URLSearchParams(useLocation().search);
  const [mainData, setMainData] = useState({
    idioma: "Español",
  });
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [imageUpload, setImageUpload] = useState(null);
  const [urlValid, setUrlValid] = useState(null);
  const navigate = useNavigate();
  const [ img, setImg ] = useState("")

  const changeForm = (name) => {
    return handleInput(name, setMainData);
  };

  useEffect(() => {
    if (query.get("edit")) {
      loadApi(`artista/canciones/${query.get("edit")}`, true, "get")
        .then((response) => {
          const { nombre, descripcion, distribucion, url_img } = response.data
          setMainData({
            nombre, descripcion, distribucion
          });
          setImg(url_img)
        })
        .catch((e) => {
          showSnack(e.message, "error", false);
        });
    }
  }, []);

  //validar url
  const handleValidUrl = (e) => {
    if (soundcloudToEmbed(e.target.value)) setUrlValid("soundcloud");
    else if (spotifyToEmbed(e.target.value)) setUrlValid("spotify");
    else if (youtubeToEmbed(e.target.value)) setUrlValid("youtube");
    else if (bandcampToEmbed(e.target.value)) setUrlValid("bandcamp");
    else setUrlValid(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageUpload(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      changeForm("url_img")(e);
    }
  };

  const handleSubmit = () => {
    if (!urlValid) {
      showSnack("Enlace de la canción invalida", "error");
      return;
    }

    const data = {
      ...mainData,
      url:
        urlValid === "spotify"
          ? mainData.url
          : urlValid === "soundcloud"
          ? soundcloudToEmbed(mainData.url)
          : urlValid === "youtube"
          ? mainData.url
          : urlValid === "bandcamp" && bandcampToEmbed(mainData.url),
    };

    loadApi("artista/canciones", true, "post", data, true)
      .then((response) => {
        showSnack("Se subió la canción. Revista en tu lista de canciones!");
        navigate("/artista/biblioteca");
      })
      .catch((e) => showSnack(e.message, "error"));
  };

  const handleSubmitEdit = () =>{
    loadApi(`artista/canciones/${query.get("edit")}`, true, "put", mainData, true)
      .then((response) => {
        showSnack("Se actualizó la canción. Revista en tu lista de canciones!");
        navigate("/artista/biblioteca");
      })
      .catch((e) => showSnack(e.message, "error"));
  }

  const generoOptions = generos.map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <>
      <GlobalHeader />
      <Container sx={{ pb: 5 }}>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary.main" fontWeight="900">
                {query.get("edit") ? "EDITAR CANCIÓN" : "SUBIR UNA CANCIÓN" }
              </Typography>
            </Grid>
            {!query.get("edit") && (
              <Grid item xs={12} lg={8.4}>
                <TextField
                  fullWidth
                  onChange={(event) => {
                    changeForm("url")(event);
                    handleValidUrl(event);
                  }}
                  placeholder="Pega aqui el enlace de tu canción"
                  variant="standard"
                />
              </Grid>
            )}
            {!query.get("edit") && (
              <Grid item xs={12} ml={-1}>
                <Typography color="grayText" fontWeight="400">
                  Si el enlace es de bandCamp o soundcloud, pegar codigo embed.
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    color: "grayText",
                  }}
                >
                  <FaSpotify
                    color={urlValid === "spotify" ? colors.spotify : "grayText"}
                  />
                  <FaSoundcloud
                    color={
                      urlValid === "soundcloud" ? colors.soundcloud : "grayText"
                    }
                  />
                  <FaBandcamp
                    color={
                      urlValid === "bandcamp" ? colors.bandcamp : "grayText"
                    }
                  />
                  <FaYoutube
                    color={urlValid === "youtube" ? colors.youtube : "grayText"}
                  />
                </Typography>
                {/* <img src="/assets/clipboard2.svg" height="50" alt="plataformas" /> */}
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                defaultValue={mainData?.descripcion}
                onChange={changeForm("descripcion")}
                rows={8}
                placeholder="Descripción de la canción"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Elige una portada:
              </Typography>
              <Box>
                <LoadingButton
                  component="label"
                  loading={loadingApi.includes("post__artista/canciones")}
                  variant="outlined"
                  sx={{
                    backgroundImage: `url(${imageUpload || img})`,
                    borderRadius: "4px",
                    backgroundColor: "white",
                    backgroundSize: "cover",
                    width: "150px!important",
                    height: "150px!important",
                  }}
                >
                  {!imageUpload && "+"}
                  <input
                    type="file"
                    id="fileUpload"
                    onChange={handleFileChange}
                    style={{ width: "100%", height: "100%" }}
                    hidden
                    className="inputFileInput"
                    accept="image/*"
                  />
                </LoadingButton>
              </Box>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Rellena los campos finales
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>Nombre de la canción:</Typography>
                <TextField
                  variant="outlined"
                  onChange={changeForm("nombre")}
                  value={mainData?.nombre}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>Distribución:</Typography>
                <TextField
                  variant="outlined"
                  onChange={changeForm("distribucion")}
                  value={mainData?.distribucion}
                  fullWidth
                />
              </Grid>
              {!query.get("edit") && (
                <Grid item xs={12} sm={4}>
                  <Typography>Género musical:</Typography>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    onChange={changeForm("genero")}
                    defaultValue={generos[mainData?.genero]}
                    options={generoOptions}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                { !query.get("edit") ? (
                <LoadingButton
                  loading={loadingApi.includes("post__artista/canciones")}
                  onClick={() => handleSubmit()}
                >
                  Subir Canción
                </LoadingButton>

                ) : (
                  <LoadingButton
                  loading={loadingApi.includes(`put__artista/canciones/${query.get("edit")}`)}
                  onClick={() => handleSubmitEdit()}
                >
                  Guardar Canción
                </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
