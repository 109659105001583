import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from "react-icons/md";
import { useUser } from "../hooks/useUser";

const CardsProductores = ({
  dataItem = {},
  loading = false,
  handleOpen,
  updateFavorite,
  handleSelectProducers,
  producers,
}) => {
  const { user } = useUser();

  return (
    <Stack
      sx={{
        width: "200px",
        p: "15px",
        gap: "10px",
        borderRadius: "10px",
        justifyContent: "space-between",
        ":hover": {
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 15px",
        },
      }}
    >
      <Stack gap="10px">
        {/* header */}
        <Stack>
          <img
            src={dataItem?.profile?.profileimg || "/assets/AVATAR.jpg"}
            width="100%"
            style={{
              borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 15px",
            }}
          />
        </Stack>
        {/* body */}
        <Stack>
          <Stack direction="row">
            <Typography
              noWrap
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "170px",
              }}
            >
              {" "}
              {dataItem.profile.nombre_media}
            </Typography>
            <img
              src={`https://flagcdn.com/${dataItem.profile.pais}.svg`}
              alt="País"
              width="30"
              height="20"
            />
          </Stack>
          <Typography variant="body1" color="grayText" fontWeight={400}>
            {dataItem.profile.tipo}
          </Typography>
          <Stack>
            <Typography variant="p" fontWeight={400}>
              {dataItem?.profile?.generos_musicales ||
                []?.map((item, i) => {
                  return !(i !== dataItem.profile.generos_musicales.length - 1)
                    ? `${item}.`
                    : `${item}, `;
                })}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Seleccionar productores */}
            <Checkbox
              onChange={({ target }) => handleSelectProducers(target, dataItem)}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "400",
                cursor: "pointer",
                textDecoration: "underline",
                ":hover": { color: "#F27224" },
              }}
              onClick={() => handleOpen(dataItem.id, dataItem.profile)}
            >
              Ver mas
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* button */}
      <Stack
        direction="row"
        spacing={1}
        display={producers.length > 0 ? "none" : "inherit"}
      >
        <Button sx={{ px: 4 }} onClick={() => handleOpen(dataItem.id)}>
          Enviar
        </Button>
        <Button
          sx={{ px: "6px", fontSize: "24px", flexGrow: "1" }}
          onClick={() => updateFavorite(dataItem.id)}
          variant="text"
        >
          {user.profile.favorites &&
          JSON.parse(user.profile?.favorites).includes(dataItem.id) ? (
            <MdOutlineFavorite />
          ) : (
            <MdOutlineFavoriteBorder />
          )}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CardsProductores;
