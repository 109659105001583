import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import countries from "../../utils/countries";
import generos from "../../utils/generos";

export default function Step1({ changeForm, mainData }) {
  const countryOptions = Object.entries(countries).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const generoOptions = generos.map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <Stack alignItems="center">
      <Stack
        spacing={2.3}
        width={{ xs: "100%", sm: "90%", md: "70%", lg: "50%" }}
      >
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography variant="p">
              Ingresa el país donde se ubica tu proyecto:
            </Typography>
            {mainData?.pais && (
              <img
                src={`https://flagcdn.com/${mainData.pais}.svg`}
                alt="País"
                width="30"
                height="20"
              />
            )}
          </Stack>

          <Autocomplete
            disablePortal
            disableClearable
            onChange={changeForm("pais")}
            options={countryOptions}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">
            Ingresa una breve descripción de tu proyecto:
          </Typography>
          <TextField
            multiline
            rows={3}
            value={mainData?.descripcion || ""}
            onChange={changeForm("descripcion")}
            fullWidth
          ></TextField>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">
            Ingresa tu principal género músical:
          </Typography>
          <Autocomplete
            disablePortal
            disableClearable
            onChange={changeForm("generos_musicales")}
            options={generoOptions}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
