import { useState } from "react";

const useSnack = () => {
  const [msgSB, setMsgSB] = useState("");
  const [openSB, setOpenSB] = useState(false);
  const [typeSB, setTypeSB] = useState("success");

  const showSnack = (value, type = "success", timeout = 3000) => {
    setMsgSB(value);
    setOpenSB(true);
    setTypeSB(type);
    if (timeout) {
      setTimeout(() => {
        setOpenSB(false);
      }, timeout);
    }
  };

  return { showSnack, msgSB, openSB, typeSB };
};

export default useSnack;
