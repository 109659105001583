import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import styles from "../../components/globalstyles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Message = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const permitidos = [
    "email_verified",
    "verify_email",
    "password_recovery",
    "reset_password",
  ];

  useEffect(() => {
    if (!permitidos.includes(query.get("type"))) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {/* HEADER (SOLO MOVIL) */}
      <Box sx={styles.barramovil}>
        <img src="/assets/clipboard4.svg" alt="Radar Freim" />
      </Box>

      <Grid container sx={styles.root}>
        {/* VISTA IZQUIERDA */}
        <Grid item md={7} sx={styles.image}>
          <img alt="Radar Freim" src="/assets/logo.svg" width="40%" />
        </Grid>
        {/* VISTA DERECHA */}
        <Grid container item md={5} px={4}>
          <Stack
            width="100%"
            spacing={2}
            px={3}
            justifyContent="center"
            alignItems="center"
          >
            <CheckCircleOutlineRoundedIcon
              sx={{ color: "green", height: "auto", width: "40%" }}
            />
            <Typography align="center">
              {query.get("type") === "email_verified" &&
                "El correo se verificó correctamente!"}
              {query.get("type") === "password_recovery" &&
                "Por favor verifica tu bandeja de email, enviamos un enlace para recuperar tu contraseña!"}
              {query.get("type") === "reset_password" &&
                "Se restableció tu contraseña correctamente, Inicia sesión para continuar!"}
              {query.get("type") === "verify_email" &&
                "Registro exitoso! Por favor verifica tu bandeja de email para activar tu cuenta, si no encuentras el email, buscalo en spam."}
            </Typography>
            <Button LinkComponent={Link} to="/">
              Continuar
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Message;
