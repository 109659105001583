import {
  Autocomplete,
  Box,
  Container,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";
import useApi from "../../../hooks/useApi";
import { useUser } from "../../../hooks/useUser";
import { useEffect, useState } from "react";
import handleInput from "../../../utils/handleInput";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { LoadingButton } from "@mui/lab";
import countries from "../../../utils/countries";

//icons
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

export default function ViewProfile() {
  const { loadApi, loadingApi } = useApi();
  const { user, updateUser } = useUser();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [editDataA, setEditDataA] = useState({});
  const [editDataP, setEditDataP] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const editMode = !false;
  let redes;
  if (user.profile.redes_sociales)
    redes = JSON.parse(user.profile.redes_sociales);

  useEffect(() => {
    if (user.profile.redes_sociales)
      setEditDataP((adata) => ({
        ...adata,
        redes_sociales: JSON.parse(user.profile.redes_sociales),
      }));
  }, []);

  const countryOptions = Object.entries(countries).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const changeForm = (name) => {
    return handleInput(name, setEditDataA);
  };

  const changeFormP = (name) => {
    return handleInput(name, setEditDataP);
  };

  function updateAccount() {
    loadApi("user", true, "put", editDataA)
      .then((response) => {
        showSnack(response.data.message);
        updateUser();
      })
      .catch((e) => {
        showSnack(e.message, "error");
      });
  }

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    const previewURL = URL.createObjectURL(selectedImage);
    setPreviewImage(previewURL);
    setEditDataP((adata) => ({ ...adata, profileimg: selectedImage }));
  };

  function updateProfile() {
    const data = editDataP;
    if (editDataP.redes_sociales !== undefined)
      data["redes_sociales"] = JSON.stringify(editDataP?.redes_sociales);

    loadApi("artista/profile", true, "put", data, true)
      .then((response) => {
        showSnack(response.data.message);
        updateUser();
        setEditDataP({});
      })
      .catch((e) => {
        showSnack(e.message, "error");
      });
  }

  //imagen de perfil
  const styleImage = {
    width: "200px",
    height: "200px",
    borderRadius: "10px",
    objectFit: "cover",
  };

  return (
    <>
      <GlobalHeader />
      <Container sx={{ pb: 4 }}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h4" my={1}>
              Imagen de perfil
            </Typography>
          </Grid>
          <Grid container item justifyContent={{ xs: "center", sm: "start" }}>
            <img
              src={previewImage || user.profile.profileimg}
              style={styleImage}
            />
            <LoadingButton
              component="label"
              variant="outlined"
              sx={{
                transition: "opacity 0.3s",
                borderRadius: "4px",
                backgroundColor: "white",
                backgroundSize: "cover",
                minWidth: "0%",
                width: "30px!important",
                height: "30px!important",
                position: "absolute",
                marginLeft: "185px",
                marginTop: "-7px",
                zIndex: "10",
                padding: "0",
                color: "#666",
                borderColor: "#666",
                borderRadius: "50%",
                ":hover": {
                  backgroundColor: "white",
                  borderColor: "#666",
                  transform: "scale(1.02)",
                },
              }}
            >
              <AddAPhotoIcon sx={{ width: "18px" }} />
              <input
                type="file"
                onChange={handleImageChange}
                style={{ width: "100%", height: "100%" }}
                hidden
                accept="image/*"
              />
            </LoadingButton>
          </Grid>
          {/* PERFIL PUBLICO */}
          <Grid item>
            <Typography variant="h4" my={1} fontWeight={600}>
              Mi Perfil Público
            </Typography>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>Tipo</Typography>
                <Select
                  name="tipo"
                  defaultValue={user.profile.tipo}
                  onChange={changeFormP("tipo")}
                >
                  {tiposArtista.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>Nombre de artista</Typography>
                <TextField
                  variant="outlined"
                  name="nombre_media"
                  defaultValue={user.profile.nombre_media}
                  onChange={changeFormP("nombre_media")}
                  disabled={!editMode}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>País</Typography>
                  <img
                    src={`https://flagcdn.com/${
                      editDataP.pais || user.profile.pais
                    }.svg`}
                    alt="País"
                    width="30"
                    height="20"
                  />
                </Stack>
                <Autocomplete
                  disablePortal
                  disableClearable
                  defaultValue={countries[user.profile.pais]}
                  onChange={changeFormP("pais")}
                  options={countryOptions}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>Nombre del encargado</Typography>
                <TextField
                  variant="outlined"
                  defaultValue={user.profile.nombre_encargado}
                  name="nombre_encargado"
                  onChange={changeFormP("nombre_encargado")}
                  disabled={!editMode}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>Página Web</Typography>
                <TextField
                  variant="outlined"
                  defaultValue={user.profile.pagina_web}
                  name="pagina_web"
                  onChange={changeFormP("pagina_web")}
                  disabled={!editMode}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>Email para el contacto</Typography>
                <TextField
                  variant="outlined"
                  defaultValue={user.profile.email_contacto}
                  name="email_contacto"
                  onChange={changeFormP("email_contacto")}
                  disabled={!editMode}
                />
              </Stack>
            </Grid>
            {/* Redes sociales */}
            <Grid item xs={12}>
              <Typography>Redes sociales</Typography>
              <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                  sx={{ flexGrow: 1, minWidth: "300px" }}
                  variant="outlined"
                  defaultValue={redes?.facebook}
                  onChange={(e) =>
                    setEditDataP((adata) => ({
                      ...adata,
                      redes_sociales: {
                        ...adata?.redes_sociales,
                        facebook: e.target.value,
                      },
                    }))
                  }
                  label="Url de facebok"
                />
                <TextField
                  sx={{ flexGrow: 1, minWidth: "300px" }}
                  variant="outlined"
                  defaultValue={redes?.instagram}
                  onChange={(e) =>
                    setEditDataP((adata) => ({
                      ...adata,
                      redes_sociales: {
                        ...adata?.redes_sociales,
                        instagram: e.target.value,
                      },
                    }))
                  }
                  label="Url de instagram"
                />
                <TextField
                  sx={{ flexGrow: 1, minWidth: "300px" }}
                  variant="outlined"
                  defaultValue={redes?.spotify}
                  onChange={(e) =>
                    setEditDataP((adata) => ({
                      ...adata,
                      redes_sociales: {
                        ...adata?.redes_sociales,
                        spotify: e.target.value,
                      },
                    }))
                  }
                  label="Url de spotify"
                />
                <TextField
                  sx={{ flexGrow: 1, minWidth: "300px" }}
                  variant="outlined"
                  defaultValue={redes?.twitter}
                  onChange={(e) =>
                    setEditDataP((adata) => ({
                      ...adata,
                      redes_sociales: {
                        ...adata?.redes_sociales,
                        twitter: e.target.value,
                      },
                    }))
                  }
                  label="Url de twitter"
                />
                <TextField
                  sx={{ flexGrow: 1, minWidth: "300px" }}
                  variant="outlined"
                  defaultValue={redes?.youtube}
                  onChange={(e) =>
                    setEditDataP((adata) => ({
                      ...adata,
                      redes_sociales: {
                        ...adata?.redes_sociales,
                        youtube: e.target.value,
                      },
                    }))
                  }
                  label="Url de youtube"
                />
                <TextField
                  sx={{ flexGrow: 1, minWidth: "300px" }}
                  variant="outlined"
                  defaultValue={redes?.tiktok}
                  onChange={(e) =>
                    setEditDataP((adata) => ({
                      ...adata,
                      redes_sociales: {
                        ...adata?.redes_sociales,
                        tiktok: e.target.value,
                      },
                    }))
                  }
                  label="Url de tiktok"
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loadingApi.includes("put__artista/profile")}
              onClick={() => updateProfile()}
              // onClick={() => console.log(red)}
            >
              Guardar
            </LoadingButton>
          </Grid>
          {/* MI CUENTA */}
          <Grid item>
            <Typography variant="h4" mt={3} fontWeight={600}>
              Mi cuenta
            </Typography>
          </Grid>
          <Grid container item spacing={3} direction="row">
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>Nombre</Typography>
                <TextField
                  variant="outlined"
                  defaultValue={user.name}
                  onChange={changeForm("name")}
                  disabled={!editMode}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>
                  Contraseña (Dejar en blanco si la quiere mantener)
                </Typography>
                <TextField
                  variant="outlined"
                  onChange={changeForm("password")}
                  disabled={!editMode}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack>
                <Typography>Correo Electrónico</Typography>
                <TextField variant="outlined" disabled value={user.username} />
              </Stack>
            </Grid>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loadingApi.includes("put__user")}
              onClick={updateAccount}
            >
              Guardar
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}

const tiposArtista = [
  "Solista",
  "Grupo Musical",
  "Sello Discográfico",
  "Manager o RP",
];
