import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import countries from "../../../utils/countries";
import getCurrentDate from "../../../utils/getCurrentDate";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import youtubeToEmbed from "../../../utils/youtubeToEmbed";
import spotifyToEmbed from "../../../utils/spotifyToEmbed";
import { Globals } from "../../../utils/environtments";
import { getDateAndHour, getTime } from "../../../utils/date";
import {
  FaSpotify,
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaFacebook,
} from "react-icons/fa";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import { verifyPlan } from "../../../utils/plan";

const socialNetwork = [
  { name: "facebook", Icon: FaFacebook },
  { name: "instagram", Icon: FaInstagram },
  { name: "youtube", Icon: FaYoutube },
  { name: "spotify", Icon: FaSpotify },
  { name: "tiktok", Icon: FaTiktok },
];

export default function Cancion({
  mainData,
  loadingApi,
  updateSend,
  showSnack,
}) {
  useEffect(() => {
    adjustIframe();
    window.addEventListener("resize", adjustIframe);
    return () => {
      window.removeEventListener("resize", adjustIframe);
    };
  }, []);

  function adjustIframe() {
    const item = window.document.querySelector(
      "#iframePrincipal" + mainData.id
    );
    item.style.height = (item.offsetWidth * 9) / 16 + "px";
  }

  function obtenerEmbed() {
    const item = window.document.querySelector(
      "#iframePrincipal" + mainData.id
    );
    const nuevoEmbed = srcCodigoEmbed(
      item.innerHTML,
      `${Globals.FRONT_URL}/embed/${mainData.id}`
    );
    navigator.clipboard
      .writeText(`${nuevoEmbed}`)
      .then(() => {
        showSnack("Texto copiado al portapapeles");
      })
      .catch((err) => {
        showSnack("Error al copiar el texto: ", "error");
      });
  }

  const srcCodigoEmbed = (codigoEmbed, nuevoSrc) => {
    // Buscar el inicio de la propiedad src
    const inicioSrc = codigoEmbed.indexOf('src="');
    if (inicioSrc === -1) {
      // La propiedad src no se encontró
      return codigoEmbed;
    }

    // Buscar el final de la propiedad src
    const finSrc = codigoEmbed.indexOf('"', inicioSrc + 5);
    if (finSrc === -1) {
      // No se encontró el cierre de las comillas de la propiedad src
      return codigoEmbed;
    }

    // Extraer la parte del código antes y después de la propiedad src
    const codigoAntesSrc = codigoEmbed.substring(0, inicioSrc + 5);
    const codigoDespuesSrc = codigoEmbed.substring(finSrc);

    // Construir el nuevo código con el src modificado
    const nuevoCodigoEmbed = codigoAntesSrc + nuevoSrc + codigoDespuesSrc;

    return nuevoCodigoEmbed;
  };

  return (
    <>
      <Container sx={{ borderBottom: "2px dashed gray", mb: 6 }}>
        <Grid container spacing={2} mb={5}>
          {/* COLUMNA IZQUIERDA */}
          <Grid item xs={12} md={7} lg={7}>
            <Stack spacing={2}>
              <Typography
                noWrap
                variant="h4"
                sx={{
                  paddingBottom: 1,
                  borderBottom: "2px solid gray",
                }}
              >
                {mainData.nombre}
              </Typography>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{ width: { xs: "300px" } }}
                    display="flex"
                    justifyContent="center"
                  >
                    <img
                      src={mainData.url_img}
                      style={{ borderRadius: "4px/6.7px" }}
                      width="100%"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Stack spacing={1}>
                    <Typography variant="h6">
                      {mainData.profile.nombre_media}
                    </Typography>
                    <Typography variant="p">
                      Fecha de envio: {getDateAndHour(mainData.fecha)}
                    </Typography>
                    <Typography variant="p">
                      Tiempo restante:{" "}
                      <Typography variant="p" color="red" fontWeight={700}>
                        {getTime(mainData.fecha)}
                      </Typography>
                    </Typography>
                    <Typography variant="p">
                      País: {countries[mainData.profile.pais]}
                    </Typography>
                    <Typography variant="p">
                      Género: {mainData.genero}
                    </Typography>
                    <Typography variant="subtitle1">Redes sociales:</Typography>
                    <Stack direction="row" flexWrap="wrap" gap={1}>
                      {socialNetwork.map(({ name, Icon }, i) => (
                        <Button
                          key={i}
                          sx={{
                            py: 1,
                            px: 1,
                            minWidth: "auto",
                            ":hover": {
                              color: "#ffffff",
                              bgcolor: "#F27224",
                            },
                          }}
                          variant="outlined"
                          href={mainData[name]}
                          target="_blank"
                        >
                          <Icon />
                        </Button>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          {/* COLUMNA DERECHA */}
          <Grid item xs={12} md={5} lg={5}>
            <Stack spacing={3}>
              <Stack alignItems="center">
                {youtubeToEmbed(mainData.url) ? (
                  <Box
                    id={`iframePrincipal${mainData.id}`}
                    width="100%"
                    sx={{ iframe: { borderRadius: "4px/6.7px" } }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      style={{ maxWidth: "560px", maxHeight: "340px" }}
                      src={youtubeToEmbed(mainData.url)}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                ) : spotifyToEmbed(mainData.url) ? (
                  <Box
                    id={`iframePrincipal${mainData.id}`}
                    width="100%"
                    sx={{ iframe: { borderRadius: "4px/6.7px" } }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      style={{ maxWidth: "560px", maxHeight: "340px" }}
                      src={spotifyToEmbed(mainData.url)}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                ) : (
                  <Box
                    id={`iframePrincipal${mainData.id}`}
                    width="100%"
                    sx={{ iframe: { borderRadius: "4px/6.7px" } }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      style={{ maxWidth: "560px", maxHeight: "340px" }}
                      src={mainData.url}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                )}
              </Stack>
              <Stack
                direction="row"
                spacing={1.3}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" align="left">
                  Obtener codigo embed
                </Typography>
                <Button color="info" onClick={obtenerEmbed}>
                  Copiar
                </Button>
              </Stack>
              <Typography
                variant="h5"
                color="primary"
                sx={{
                  borderBottom: "2px dashed gray",
                }}
              ></Typography>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <LoadingButton
                  onClick={() =>
                    updateSend("approve", mainData.id, mainData.url)
                  }
                  color="success"
                  sx={{ textTransform: "none" }}
                  startIcon={<CheckCircleOutlineOutlinedIcon />}
                >
                  Aprobar
                </LoadingButton>
                <LoadingButton
                  onClick={() =>
                    updateSend("rejected", mainData.id, mainData.url)
                  }
                  color="error"
                  sx={{ textTransform: "none" }}
                  startIcon={<DoDisturbAltOutlinedIcon />}
                >
                  Rechazar
                </LoadingButton>
              </Stack>
              <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h6" fontWeight="600">
                    ${mainData.amount}USD -{" "}
                  </Typography>
                  <Typography variant="h6" color="red">
                    {verifyPlan(mainData.amount).fullname}
                  </Typography>
                </Stack>
                <Typography variant="subtitle1" fontWeight="500">
                  {verifyPlan(mainData.amount).description}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="h5">Descripción de la canción</Typography>
                <Typography variant="p">
                  <pre
                    style={{
                      fontFamily: "'Roboto', Helvetica, Arial",
                      fontWeight: "400",
                    }}
                  >
                    {mainData.descripcion}
                  </pre>
                </Typography>
                <Typography variant="h5">Perfil</Typography>
                <Typography variant="p">
                  {mainData.profile.descripcion}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
