import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import GlobalHeader from "../../components/GlobalHeader";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import handleInput from "../../utils/handleInput";
import Step1 from "./step1A";
import Step2 from "./step2A";
import Step3 from "./step3A";
import Step4 from "./step4A";
import Step1P from "./step1P";
import Step2P from "./Step2P";
import Step3P from "./step3P";
import { useUser } from "../../hooks/useUser";
import { isEmail, isURL } from "validator";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";

export default function ViewCompleteProfile() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageUpload, setImageUpload] = useState();
  const [mainData, setMainData] = useState({});
  const [tags, setTags] = useState([]);
  const [step, setStep] = useState(1);
  const [animating, setAnimating] = useState(false);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { user, updateUser } = useUser();
  const { loadApi, loadingApi } = useApi();
  const navigate = useNavigate();
  const nsteps = user.role === "artista" ? 4 : 3;

  const changeForm = (name) => {
    return handleInput(name, setMainData);
  };

  function validateForm() {
    // ARTISTA
    if (user.role === "artista") {
      if (step === 1) {
        if (
          !mainData?.nombre_media ||
          !mainData?.nombre_encargado ||
          !mainData?.tipo
        ) {
          showSnack("Debes completar todos los campos!", "warning");
          return false;
        }
      }

      if (step === 2) {
        if (!imageUpload) {
          showSnack("Debes subir La imagen!", "warning");
          return false;
        }
      }

      if (step === 3) {
        if (!isEmail(mainData?.email_contacto)) {
          showSnack("Debes ingresar un email válido!", "warning");
          return false;
        }
        if (!isURL(mainData?.pagina_web)) {
          showSnack("Debes ingresar una página web válida!", "warning");
          return false;
        }
      }

      if (step === 4) {
        if (
          !mainData?.descripcion ||
          !mainData?.nombre_encargado ||
          !mainData?.pais
        ) {
          showSnack("Debes completar todos los campos!", "warning");
          return false;
        }
      }
    }
    // PRODUCTOR
    else {
      if (step === 1) {
        if (
          !mainData?.nombre_media ||
          !mainData?.descripcion ||
          !mainData?.tipo
        ) {
          showSnack("Debes completar todos los campos!", "warning");
          return false;
        }
      }

      if (step === 2) {
        if (selectedFiles.length < 1) {
          showSnack("Debes subir una imagen para !", "warning");
          return false;
        }
      }
    }

    return true;
  }

  function changeStep() {
    if (validateForm()) {
      if (step < nsteps) {
        setAnimating(true);
        setTimeout(() => {
          setStep(step + 1);
          setAnimating(false);
        }, 200);
      } else {
        loadProfile();
      }
    }
  }

  function goBack() {
    if (step > 1) {
      setAnimating(true);
      setTimeout(() => {
        setStep(step - 1);
        setAnimating(false);
      }, 200);
    }
  }

  const transitionStyles = (status) => {
    if (status === "entering" || status === "exited") {
      return {
        opacity: 0,
        transition: "opacity 200ms ease-in",
      };
    } else if (status === "entered" || status === "exiting") {
      return {
        opacity: 1,
        transition: "opacity 200ms ease-in",
      };
    }
  };

  function loadProfile() {
    var formData = {};
    if (user.role === "artista") {
      formData = {
        tipo: mainData?.tipo,
        nombre_media: mainData?.nombre_media,
        nombre_encargado: mainData?.nombre_encargado,
        descripcion: mainData?.descripcion,
        pagina_web: mainData?.pagina_web,
        email_contacto: mainData?.email_contacto,
        generos_musicales: mainData.generos_musicales,
        pais: mainData?.pais,
        profileimg: selectedFiles,
      };
    }
    if (user.role === "productor") {
      formData = {
        tipo: mainData?.tipo,
        nombre_media: mainData?.nombre_media,
        nombre_encargado: "",
        descripcion: mainData?.descripcion,
        pagina_web: "",
        redes_sociales: "",
        email_contacto: "",
        generos_musicales: tags,
        pais: mainData?.pais,
        profileimg: selectedFiles,
      };
    }
    console.log(formData);

    loadApi("user/complete_profile", true, "post", formData, true)
      .then(async (response) => {
        await updateUser();
        navigate("/");
      })
      .catch((e) => {
        showSnack(e.message, "error");
      });
  }

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack>
          <Box pb={3}>
            <Typography
              variant="h4"
              color="primary.main"
              align="center"
              fontWeight="900"
            >
              COMPLETA TU PERFIL
            </Typography>
          </Box>
          {/* STEPS BOX ALL */}
          <Box
            sx={{
              ...transitionStyles(
                animating && step === 1
                  ? "exiting"
                  : step === 1
                  ? "entered"
                  : "exited"
              ),
            }}
          >
            {step === 1 && (
              <>
                {user.role === "artista" && (
                  <Step1 mainData={mainData} changeForm={changeForm} />
                )}
                {user.role === "productor" && (
                  <Step1P mainData={mainData} changeForm={changeForm} />
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              ...transitionStyles(
                animating && step === 2
                  ? "exiting"
                  : step === 2
                  ? "entered"
                  : "exited"
              ),
            }}
          >
            {step === 2 && (
              <>
                {user.role === "artista" && (
                  <Step2
                    setSelectedFiles={setSelectedFiles}
                    selectedFiles={selectedFiles}
                    showSnack={showSnack}
                    imageUpload={imageUpload}
                    setImageUpload={setImageUpload}
                  />
                )}
                {user.role === "productor" && (
                  <Step2P
                    setSelectedFiles={setSelectedFiles}
                    selectedFiles={selectedFiles}
                    showSnack={showSnack}
                    imageUpload={imageUpload}
                    setImageUpload={setImageUpload}
                  />
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              ...transitionStyles(
                animating && step === 3
                  ? "exiting"
                  : step === 3
                  ? "entered"
                  : "exited"
              ),
            }}
          >
            {step === 3 && (
              <>
                {user.role === "artista" && (
                  <Step3
                    mainData={mainData}
                    changeForm={changeForm}
                    showSnack={showSnack}
                    tags={tags}
                    setTags={setTags}
                  />
                )}
                {user.role === "productor" && (
                  <Step3P
                    mainData={mainData}
                    changeForm={changeForm}
                    showSnack={showSnack}
                    tags={tags}
                    setTags={setTags}
                  />
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              ...transitionStyles(
                animating && step === 4
                  ? "exiting"
                  : step === 4
                  ? "entered"
                  : "exited"
              ),
            }}
          >
            {step === 4 && (
              <Step4 mainData={mainData} changeForm={changeForm} />
            )}
          </Box>
          {/* STEPS BOX ALL END */}
          <Stack justifyContent="center" direction="row" spacing={2} pt={3}>
            {step > 1 && (
              <LoadingButton onClick={goBack}>&lt; Atrás</LoadingButton>
            )}
            <LoadingButton
              loading={loadingApi.includes("post__user/complete_profile")}
              onClick={changeStep}
            >
              {step === nsteps ? "Finalizar" : `Siguiente >`}
            </LoadingButton>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
